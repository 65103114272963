import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { observer } from "mobx-react-lite";
import useAlert from '../../util/Alert';
import { useStore } from '../../store/hooks';
import $api from '../../http/api.config';
import {ru} from "date-fns/locale";
import DatePicker from "react-datepicker";
import {
	PencilSquare,
	PlusSquareFill,
	XSquareFill
} from "react-bootstrap-icons";
import {toJS} from "mobx";
import {EXECUTOR} from "../../util/constants";
import {getFullUserNameOrUsername} from "../../util/user";

const SubtasksBlock = observer(({
	title,
	project
}) => {
	const baseStore = useStore("baseStore");
	const authStore = useStore("authStore");
	const params = useParams();
	const [disabled, setDisabled] = useState(true);
	const [subtasks, setSubtasks] = useState([]);
	const [alert, setAlert] = useAlert();
	const projectId = params.id;
	const { user, permissions } = authStore;
	const [error, setError] = useState(null);
    const users = authStore.guest_mode
        ? toJS(baseStore.getUsers.filter(u => u.id === project.user)) // Только project.user
        : toJS(baseStore.getUsers.filter(u => u.groups.includes(EXECUTOR))); // Все исполнители

	useEffect(() => {
		$api.get(`/api/v1/projects/${projectId}/subtasks/`).then(response => {
			let elements = response.data.map(el => {
				el.changed = false;
				el.initial_explorer = el.to_whom;
				return el;
			});
			setSubtasks(elements);
		});
	}, []);

	const create = () => {
		let data = {
				done: false,
				message: "",
				comment: "",
				to_whom: project.user,
				project: projectId,
				changed: true
		};
		setSubtasks([...subtasks, data]);
	}

	const propChanged = (e, index, type) => {
		const fieldType = e.target.type;

		if(fieldType) {
			if (fieldType === 'checkbox') {
				subtasks[index][type] = e.target.checked;
			} else if(fieldType === 'select-one') {
				subtasks[index][type] = Number(e.target.options[e.target.selectedIndex].value);
			}			
		} else {
			subtasks[index][type] = e.currentTarget.textContent;
		}
		subtasks[index].changed = true;
		setSubtasks(subtasks);
	}

	const update = () => {
		if(!validateSubtasks()) {
			return;
		}
		$api.put(
			`/api/v1/projects/${projectId}/update_subtasks/`, 
			subtasks.filter(e => e.changed)
		).then(response => {
			const updatedSubtasks = response.data;
			setAlert(response);
			setSubtasks((prevSubtasks) =>
				prevSubtasks.map((subtask) => {
					const updated = updatedSubtasks.find((updatedSubtask) => updatedSubtask.id === subtask.id);
					
					if (updated) {
						return {
							...updated,
							changed: false,
							initial_explorer: subtask.to_whom
						};
					}

					return subtask.id ? subtask : null;
				}).filter(subtask => subtask !== null)
				.concat(
					response.data.filter(newSubtask => 
						!prevSubtasks.some(existingSubtask => existingSubtask.id === newSubtask.id)
					)
				)
			);
		}).catch(err => {
			setAlert(err.response)
		});
	}

	const deleteTask = (index) => {
		$api.delete(`/api/v1/projects/${projectId}/subtasks/${subtasks[index].id}/`).then(response => {
			setSubtasks([...subtasks.filter(item => item.id !== subtasks[index]?.id)]);
			setAlert(response)
		}).catch(err => {
			setAlert(err.response)
		});
	}

	const validateSubtasks = () => {

    const newTasksWithoutToWhom = subtasks
        .filter((task) => !task.id) // Выбираем только новые задачи (у которых нет ID)
        .some((task) => !task.to_whom); // Проверяем, есть ли задачи без to_whom

    if (newTasksWithoutToWhom) {
        setError("Для новых задач должен быть указан исполнитель.");
        return false;
    }
    setError(null); // Очищаем ошибку, если все в порядке
    return true;
	};

	const handleEditButton = () => setDisabled(!disabled);

	const checkCreatedBy = (created_by) => {
		return user.id === created_by || created_by === undefined;
	}

	const checkToWhom = (to_whom) => {
		return user.id === to_whom;
	}

	return (
		<>
			{permissions.subtask.canChange && <div className="row">
				<div className="col d-flex justify-content-end">
					<div onClick={handleEditButton} className="manipulate-btn">
						<PencilSquare />
					</div>
				</div>
			</div>}

			<div className="todo-block">
				<div className="row">
					<div className="col text-center">
						<h2>{title}</h2>
					</div>
				</div>
				<div className="content container-fluid">
					{(subtasks && subtasks.length && permissions.subtask.canView) ?
						<div className='task'>
							{ subtasks.map((element, index) => {
								const userNotInExecutor = !users.some(u => u.id === element.initial_explorer);

								return <div className='row' key={index} style={
									(checkCreatedBy(element.created_by_id) || checkToWhom(element.to_whom)) ?
										(element.done ? {background: 'rgba(230, 240, 230)'} : {background: 'rgba(255,240,230)'}) :
										{}
								}>
									<div className="col">
										<div className="row fields">
											<div className="col col-auto">
												<input
													onClick={e => {
														propChanged(e, index, 'done')
													}}
													className="form-check-input"
													type="checkbox"
													defaultChecked={element.done}
													disabled={disabled || !checkToWhom(element.to_whom)}
													data-label="Статус"
												/>
											</div>
											<div
												onInput={e => {
													propChanged(e, index, 'message')}
												}
												className="col text-column"
												contentEditable={!disabled && checkCreatedBy(element.created_by_id)}
												suppressContentEditableWarning={true}
												data-label="Сообщение"
											>
												{element.message}
											</div>
											<div
												onInput={e => propChanged(e, index, 'comment')}
												className="col text-column"
												contentEditable={!disabled && checkToWhom(element.to_whom)}
												suppressContentEditableWarning={true}
												data-label="Ответ"
											>
												{element.comment}
											</div>
											<div className="col col-auto" data-label="Выполняет">
												<select
													onChange={e => propChanged(e, index, 'to_whom')}
													className="form-select border border-light"
													defaultValue={element.initial_explorer || element.to_whom}
													disabled={authStore.guest_mode || disabled || !checkCreatedBy(element.created_by_id)}
												>
													{userNotInExecutor && (
														<option value={element.initial_explorer} disabled>
																{getFullUserNameOrUsername(toJS(baseStore.getUsers), element.initial_explorer)}
														</option>
													)}
													{ users && users.map((item, key) => {
														return <option
															key={key}
															value={item.id}
														>
															{getFullUserNameOrUsername(users, item.id)}
														</option>
													}) }
												</select>
											</div>
											<div className="col col-auto">
												{permissions.subtask.canDelete &&
													<div onClick={() => deleteTask(index)} className="delete-task-button" disabled={disabled}>
														<XSquareFill />
													</div>
												}
											</div>
										</div>
										<div className="row">
											<div className="col d-flex justify-content-start">
												<DatePicker
													className="form-control"
													selected={ element.start_date ? new Date(element.start_date) : new Date()}
													onChange={date => {
														subtasks[index]['start_date'] = new Date(date).toISOString().slice(0, 10);
														subtasks[index].changed = true;
														setSubtasks([...subtasks]);
													}}
													locale={ru}
													calendarStartDay={1}
													popperPlacement="bottom-end"
													dateFormat="dd.MM.yyyy"
													disabled={disabled || !(checkCreatedBy(element.created_by_id) || checkToWhom(element.to_whom))}
												/>
												<DatePicker
													className="form-control ms-2"
													selected={ element.end_date ? new Date(element.end_date) : new Date()}
													onChange={date => {
														subtasks[index]['end_date'] = new Date(date).toISOString().slice(0, 10);
														subtasks[index].changed = true;
														setSubtasks([...subtasks]);
													}}
													locale={ru}
													calendarStartDay={1}
													popperPlacement="bottom-end"
													dateFormat="dd.MM.yyyy"
													disabled={disabled || !(checkCreatedBy(element.created_by_id) || checkToWhom(element.to_whom))}
												/>
											</div>
										</div>
									</div>
									</div>  })
							}
						</div>
						:
						<div className="mb-3 text-center">Нет подзадач</div>
					}
				</div>

				{alert && <div className="row">
					<div className="col">
						{alert}
					</div>
				</div>}

				{error && <div className="row">
					<div className="col">
						<div className="alert alert-danger">
							{error}
						</div>
					</div>
				</div>}

				{(!disabled && permissions.subtask.canAdd) &&
					<div className="row">
						<div className="col col-12 d-flex justify-content-center">
							<div onClick={() => create()} className="add-subtask-btn">
								<PlusSquareFill />
							</div>
						</div>
					</div>}
			</div>

			{(!disabled && permissions.subtask.canChange && subtasks.length > 0) &&
				<div className='row mt-3'>
					<div className="col d-flex justify-content-end">
						<Button onClick={update} className='button-outline' variant='outline-dark'>
							Сохранить изменения
						</Button>
					</div>
				</div>
			}
		</>
	)
});

export default SubtasksBlock;