/**
* Правильное форма слова
*/
function getCorrectWordForm(number, forms) {
    if (!Array.isArray(forms) || forms.length !== 3) {
        throw new Error("forms должен быть массивом из трех форм: [форма_1, форма_2, форма_5]");
    }

    const lastDigit = Math.abs(number) % 10; // Последняя цифра числа
    const lastTwoDigits = Math.abs(number) % 100; // Последние две цифры числа

    if (lastTwoDigits >= 11 && lastTwoDigits <= 14) {
        return forms[2]; // Если число заканчивается на 11-14, используем форму множественного числа
    }

    if (lastDigit === 1) {
        return forms[0]; // Форма для 1 (например, "комментарий")
    } else if (lastDigit >= 2 && lastDigit <= 4) {
        return forms[1]; // Форма для 2-4 (например, "комментария")
    } else {
        return forms[2]; // Форма для остальных случаев (например, "комментариев")
    }
}

/**
* Извлекаем наибольшее число из строки
*/
function extractNumber(str) {
    // Ищем все последовательности цифр в строке
    const matches = str.match(/\d+/g);

    if (!matches) {
        return 0; // Если чисел нет, возвращаем 0
    }

    // Преобразуем найденные последовательности в числа и находим максимальное
    return Math.max(...matches.map(Number));
}

export {
	getCorrectWordForm,
  extractNumber,
}