import {Link} from "react-router-dom";
import {useEffect, useState, useRef} from "react";
import Loader from "../loader/Loader";
import {Button} from "react-bootstrap";
import AddProjectModal from "../modals/AddProjectModal";
import FilterModal from "../modals/FilterModal";
import Accordion from 'react-bootstrap/Accordion';
import MainPageAccordionCustomToggle from '../parts/MainPageAccordionCustomToggle';
import SearchBlock from "../widgets/SearchBlock";
import $api from '../../http/api.config';
import {useStore} from '../../store/hooks';
import CustomProgressBar from "../parts/CustomProgressBar";
import {
    PlusSquareFill,
    Calendar2Event,
    List,
    ChevronCompactDown,
    PersonGear,
    SortDown,
    Funnel,
} from 'react-bootstrap-icons';
import {statusOptions} from "../../util/choices/ProjectOptions";
import GanttChart from "../parts/Chart/GanttChart";
import AddOverworkModal from "../modals/AddOverworkModal";
import { toJS } from 'mobx';
import { getFullUserNameOrUsername } from '../../util/user';
import Subtasks from '../parts/subtasks/Subtasks';

const Homepage = () => {
    const authStore = useStore('authStore');
    const baseStore = useStore('baseStore');
    const [projects, setProjects] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [nextPageUrl, setNextPageUrl] = useState(null);
    const [showAddProjectModal, setShowAddProjectModal] = useState(false);
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [showOverworkModal, setShowOverworkModal] = useState(false);
    const [showLoadMoreLoader, setShowLoadMoreLoader] = useState(false);
    const [showSubtasks, setShowSubtasks] = useState(false);
    const [showGanttDiagram, setShowGanttDiagram] = useState(false);
    const [filterData, setFilterData] = useState({});
    const { permissions } = authStore;
    const currentUser = authStore.user;
    const selectRef = useRef(null);

    useEffect(() => {
        let filterSet = toJS(baseStore.projectsFilterSet);
        filterSet['ordering'] = '-id';

        // Получаем проекты
        $api.get(
            '/api/v1/projects/',
            {params: filterSet}
        ).then((response) => {
            setProjects([...response.data.results]);
            setNextPageUrl(response.data.next);
        }).finally(() => {
            setIsLoaded(true);
        });

        selectRef.current.value = "default";
    }, []);

    // Add new project modal
    const handleShowAddModal = () => setShowAddProjectModal(true);
    const handleCloseAddModal = () => setShowAddProjectModal(false);

    // Add filter modal
    const handleShowFilterModal = () => setShowFilterModal(true);
    const handleCloseFilterModal = () => setShowFilterModal(false);


    const loadMore = () => {
        let filterSet = toJS(baseStore.projectsFilterSet);
        
        setShowLoadMoreLoader(true);
        if (nextPageUrl) {
            const url = new URL(nextPageUrl);
            $api.get(
                url.pathname + url.search,
                { params: filterSet }
            ).then((response) => {
                const newProjects = response.data.results.filter((project) => {
                    return !projects.find((existingProject) => existingProject.id === project.id);
                });
                setProjects([...projects, ...newProjects]);
                setNextPageUrl(response.data.next);

            }).finally(() => {
                setShowLoadMoreLoader(false);
            });
        }
    }

    const appendNewProject = (project) => {
        setProjects([project, ...projects]);
    }

    const sort = (e) => {
        let value = e.target.value;
        const filterSet = {...toJS(baseStore.projectsFilterSet), ordering: value};

        setIsLoaded(false);

        // Получаем проекты
        $api.get(
            '/api/v1/projects/',
            {params: filterSet}
        ).then((response) => {
            setProjects([...response.data.results]);
            setNextPageUrl(response.data.next);
        }).finally(() => {
            setIsLoaded(true);
        });
    }

    const handleButtonClick = (event) => {
        setShowGanttDiagram(!showGanttDiagram);
    }

    return (
        <>
            <div className="container-fluid pt-4 flex-grow-1" id="mainpage">
                <div className="group_block h-100 d-flex flex-column">
                    <div className="block mb-3">
                        <div className="row">
                            <div className="col d-flex justify-content-end flex-wrap" id="additional">
                                { permissions.subtask.canView &&
                                  <button className="btn btn-light mb-2 mb-sm-0"
                                          onClick={() => setShowSubtasks(!showSubtasks)}>
                                      Подзадачи
                                  </button>
                                }
                                {permissions.overwork.canAdd &&
                                  <button type="button" className="btn btn-light mb-2 mb-sm-0 ms-2"
                                            onClick={() => setShowOverworkModal(!showOverworkModal)}>
                                        Добавить переработку
                                    </button>}
                                { permissions.project.canView && 
                                    <button type="button" className="btn btn-light ms-2 mb-2 mb-sm-0"
                                            onClick={handleButtonClick}>
                                        Диаграмма
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                    { (showSubtasks) &&
                        <Subtasks />
                    }

                    <div className="card mb-4 rounded flex-grow-1 d-flex flex-column">
                        <div className="card-header">
                            <div className="row mt-2 mb-2">
                                <div className="col align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1 text-left">Задачи</h4>
                                    <div className="flex-shrink-0">
                                        <div className="search_wrapper d-flex align-items-center">
                                            <SearchBlock/>
                                            {permissions.project.canAdd &&
                                                <div onClick={handleShowAddModal}
                                                    className="add_new_project ms-2">
                                                    <PlusSquareFill/>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2 mb-2">
                                <div className="col d-flex align-items-center mb-3 mt-2 mb-md-0 mt-md-0">
                                    <div onClick={handleShowFilterModal} className='filter-button'>
                                        <Funnel fontSize={30} title='Фильтр'/>
                                        Фильтр
                                    </div>
                                </div>

                                <div className="col col-12 col-md-5 col-lg-3">
                                    <div className="ordering-block d-flex align-items-center">
                                        <SortDown fontSize={30} className='me-2'/>
                                        <select className="form-select" onChange={sort} ref={selectRef}>
                                            <option value="default">По умолчанию</option>
                                            <option value="project_number">Номер проекта ↑</option>
                                            <option value="-project_number">Номер проекта ↓</option>
                                            <option value="status_urgently">Статус "Срочно"</option>
                                            <option value="status_on_work">Статус "В работе"</option>
                                            <option value="status_completed">Статус "Завершено"</option>
                                            <option value="end_date">Дата завершения ↑</option>
                                            <option value="-end_date">Дата завершения ↓</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            { showGanttDiagram && 
                                <>
                                    <hr />
                                    <div className="row mb-2">
                                        <div className="col">
                                        
                                            <div>
                                                <GanttChart filterData={filterData}/>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>

                        <div className="card-body flex-grow-1">
                            {(isLoaded) ?
                                <>
                                    <div className="table-head row m-0 border-bottom d-none d-lg-flex">
                                        <div className="col col-2 border-1 border-light-subtle border-end p-2 ps-3 pe-3 text-start"
                                            title="Номер заказа">
                                            #
                                        </div>
                                        <div className="col col-3 border-1 border-light-subtle border-end p-3 text-start"
                                            title="Название">
                                            <List/>&nbsp;&nbsp;Название
                                        </div>
                                        <div className="col col-4 border-1 border-light-subtle border-end p-3 text-start"
                                            title="Заказчик">
                                            <PersonGear/>&nbsp;&nbsp;Участники
                                        </div>
                                        <div className="col col-2 border-1 border-light-subtle border-end p-3 text-start align-middle"
                                            title="Дата сдачи">
                                            <Calendar2Event/>&nbsp;&nbsp;Дата сдачи
                                        </div>
                                        <div className="col col-1 p-3" title="Выполнено">
                                            Выполнено
                                        </div>
                                    </div>
                                    <Accordion defaultActiveKey="0" alwaysOpen style={{border: "0"}}
                                               className="project-list">

                                        {projects && projects.length && permissions.project?.canView ?
                                            <>
                                                {projects.map((element, index) => {
                                                    const user_name = getFullUserNameOrUsername(toJS(baseStore.getUsers), element.user);

                                                    return <div
                                                        className={"border border-light-subtle border-top-0 border-start-0 border-end-0 " + ((index % 2 === 0) && "bg-light")}
                                                        key={element.id}>
                                                        <div className="accordion-head">
                                                            <MainPageAccordionCustomToggle
                                                                eventKey={element.id}>
                                                                <div className="row m-0 outer-row">
                                                                    <div
                                                                        className="col col-number col col-12 col-md-2 border-1 border-light-subtle border-end"
                                                                        title={element.orderNumber}>
                                                                        <div className="row">
                                                                            <div
                                                                                className="col d-flex d-md-flex flex-column flex-md-row"
                                                                                data-label="№ проекта">
                                                                                <div className="text">
                                                                                    {element.project_number || '-'}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div
                                                                                className="col d-flex d-md-flex flex-column flex-md-row"
                                                                                data-label="№ у заказчика">
                                                                                <div className="text">
                                                                                    {element.customer_project_number || '-'}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col col-12 col-md-3 border-1 border-light-subtle border-end
                                                                        d-flex d-md-flex flex-column flex-md-row"
                                                                        title={element.name}
                                                                        data-label="Название">
                                                                            <div className="text">
                                                                                {element.name}
                                                                            </div>
                                                                    </div>
                                                                    <div
                                                                      className="hide-label col col-12 col-md-4 border-1 border-light-subtle border-end flex-column"
                                                                      title={baseStore.getCustomers.find(e => e.id === element.customer)?.name}
                                                                      data-label="Участники">
                                                                        <div className="mb-2">
                                                                            <p className="mb-0 text-secondary">
                                                                                Заказчик:
                                                                            </p>
                                                                            <p className="mb-0">
                                                                                {baseStore.getCustomers.find(e => e.id === element.customer)?.name || 'Не указан'}
                                                                            </p>
                                                                        </div>
                                                                        <div>
                                                                            <p className="mb-0 text-secondary">
                                                                                Исполнители:
                                                                            </p>
                                                                            <ul className="mb-0">
                                                                                <li>
                                                                                    {element.user === currentUser.id
                                                                                      ?
                                                                                      <span className='text-violet'>
                                                                                          {user_name || <span className='text-brightred'>Не назначен</span>}
                                                                                      </span>
                                                                                      :
                                                                                      <span>
                                                                                          {user_name || <span className='text-brightred'>Не назначен</span>}
                                                                                      </span>
                                                                                    }
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                        {element.subtask_users?.length > 0 &&
                                                                            <div>
                                                                                <p className="mb-0 text-secondary">Подзадачи:</p>
                                                                                <ul className="mb-0">
                                                                                    {element.subtask_users.map(e => {
                                                                                        const user_name = getFullUserNameOrUsername(toJS(baseStore.getUsers), e.id);
                                                                                        return <li>
                                                                                            <span className={currentUser?.id === e.id ? 'text-violet' : ''}>
                                                                                                {user_name}
                                                                                            </span>
                                                                                        </li>
                                                                                    })}
                                                                                </ul>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    <div
                                                                      className="col col-12 col-md-2 border-1 border-light-subtle border-end
                                                                      d-flex d-md-flex flex-column flex-md-row"
                                                                      title={element.contractShipmentDate}
                                                                      data-label="Дата">
                                                                        <div className="text text-nowrap">
                                                                        с {element.start_date.split('-').reverse().join('.')}
                                                                          <br/>
                                                                          по {element.end_date.split('-').reverse().join('.')}
                                                                        </div>
                                                                    </div>
                                                                    <div className="hide-label col col-12 col-md-1 p-3 d-flex flex-column align-items-center"
                                                                        title={element.orderNumber}
                                                                        data-label="Статус">
                                                                        <CustomProgressBar e={element}/>

                                                                        <span className="status">
                                                                          {statusOptions.find(e => e.value === element.status)?.name}
                                                                        </span>
                                                                    </div>
                                                                    <div className="col col-12 d-md-none justify-content-center pb-2">
                                                                        <ChevronCompactDown/>
                                                                    </div>
                                                                </div>
                                                            </MainPageAccordionCustomToggle>
                                                        </div>
                                                        <Accordion.Collapse eventKey={element.id}>
                                                            <div
                                                                className={"border border-start-0 border-end-0 border-bottom-0 " + (index % 2 === 0 && "bg-light")}>
                                                                <div className="row m-0">
                                                                    <div className="col col-12 col-md-6 px-3 my-3">
                                                                        <div className="row">
                                                                            <div className="col col-12 col-sm-8">
                                                                                3д модель:
                                                                            </div>
                                                                            <div className="col col-12 col-sm-4">
                                                                                <div className="form-switch d-flex align-items-center">
                                                                                    <input
                                                                                        className="form-check-input"
                                                                                        type="checkbox"
                                                                                        checked={element.model_3d_done}
                                                                                        disabled/>
                                                                                    <span className="ms-3">
                                                                                        {
                                                                                            element.model_3d_done_date ?
                                                                                            (element.model_3d_done_date.split('-').reverse().join('.')) :
                                                                                            "Дата не указана"
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col col-12 col-md-6 px-3 my-3">
                                                                        <div className="row">
                                                                            <div className="col col-12 col-sm-8">
                                                                                2д чертежи:
                                                                            </div>
                                                                            <div className="col col-12 col-sm-4">
                                                                                <div className="form-switch d-flex align-items-center">
                                                                                    <input
                                                                                        className="form-check-input"
                                                                                        type="checkbox"
                                                                                        checked={element.model_2d_done}
                                                                                        disabled/>
                                                                                    <span className="ms-3">
                                                                                        {
                                                                                            element.model_2d_done_date ?
                                                                                            (element.model_2d_done_date.split('-').reverse().join('.')) :
                                                                                            "Дата не указана"
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row m-0">
                                                                    <div className="col col-12 col-md-6 px-3 my-3">
                                                                        <div className="row">
                                                                            <div className="col col-12 col-sm-8">
                                                                                Раскрой:
                                                                            </div>
                                                                            <div className="col col-12 col-sm-4">
                                                                                <div className="form-switch d-flex align-items-center">
                                                                                    <input
                                                                                        className="form-check-input"
                                                                                        type="checkbox"
                                                                                        checked={element.sketch_done}
                                                                                        disabled/>
                                                                                    <span className="ms-3">
                                                                                        {
                                                                                            element.sketch_done_date ?
                                                                                            (element.sketch_done_date.split('-').reverse().join('.')) :
                                                                                            "Дата не указана"
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col col-12 col-md-6 px-3 my-3">
                                                                        <div className="row">
                                                                            <div
                                                                                className="col col-12 col-sm-8">
                                                                                Отправлено:
                                                                            </div>
                                                                            <div
                                                                                className="col col-12 col-sm-4">
                                                                                <div className="form-switch d-flex align-items-center">
                                                                                    <input
                                                                                        className="form-check-input"
                                                                                        type="checkbox"
                                                                                        checked={element.send_done}
                                                                                        disabled/>
                                                                                    <span className="ms-3">
                                                                                        {
                                                                                            element.send_done_date ?
                                                                                            (element.send_done_date.split('-').reverse().join('.')) :
                                                                                            "Дата не указана"
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row m-0">
                                                                    <div
                                                                        className="col col-12 col-md-6 px-3 my-3">
                                                                        <div className="row">
                                                                            <div
                                                                                className="col col-12 col-sm-8">
                                                                                Техническая
                                                                                документация:
                                                                            </div>
                                                                            <div
                                                                                className="col col-12 col-sm-4">
                                                                                <div className="form-switch d-flex align-items-center">
                                                                                    <input
                                                                                        className="form-check-input"
                                                                                        type="checkbox"
                                                                                        checked={element.tech_documentation_done}
                                                                                        disabled/>
                                                                                    <span className="ms-3">
                                                                                        {
                                                                                            element.tech_documentation_done_date ?
                                                                                            (element.tech_documentation_done_date.split('-').reverse().join('.')) :
                                                                                            "Дата не указана"
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col col-12 col-md-6 px-3 my-3">
                                                                        <div className="row">
                                                                            <div className="col col-12 col-sm-8">
                                                                                Электрическая схема:
                                                                            </div>
                                                                            <div className="col col-12 col-sm-4">
                                                                                <div className="form-switch d-flex align-items-center">
                                                                                    <input className="form-check-input"
                                                                                        type="checkbox"
                                                                                        checked={element.electric_scheme_done}
                                                                                        disabled/>
                                                                                    <span className="ms-3">
                                                                                        {
                                                                                            element.electric_scheme_done_date ?
                                                                                            (element.electric_scheme_done_date.split('-').reverse().join('.')) :
                                                                                            "Дата не указана"
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row m-0">
                                                                    <div
                                                                        className="col p-3 d-flex align-items-end justify-content-end">
                                                                        <Link
                                                                            className='btn btn-dark'
                                                                            to={'/projects/' + element.id + '/'}>
                                                                            Перейти
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Accordion.Collapse>
                                                    </div>
                                                })}
                                            </>
                                            : 
                                            <div className="text-center py-5">
                                                <p>{(!permissions.project?.canView) && 'Недостаточно прав для просмотра списка проектов'}</p>
                                                <p>{projects.length === 0 && 'Проектов пока нет'}</p>
                                            </div>}
                                    </Accordion>
                                </> : <Loader/>}
                        </div>
                    </div>

                    {(nextPageUrl && permissions.project.canView) &&
                        <div className="row d-flex justify-center">
                            <div>
                                <Button variant="outline-primary" onClick={loadMore}>
                                    Загрузить еще
                                    {showLoadMoreLoader && <>&nbsp;
                                        <div className="loader-20"></div>
                                    </>}
                                </Button>
                            </div>
                        </div>}
                </div>
            </div>

            <AddProjectModal show={showAddProjectModal}
                             onHide={handleCloseAddModal}
                             appendNewProject={appendNewProject}/>

            <AddOverworkModal show={showOverworkModal}
                           onHide={setShowOverworkModal}/>

            <FilterModal show={showFilterModal}
                         onHide={handleCloseFilterModal}
                         setElements={setProjects}
                         setNextPageUrl={setNextPageUrl}
                         setFilterParameters={setFilterData}
            />
        </>
    )
}

export default Homepage;