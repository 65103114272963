/**
 * Группы Django
 */
const ADMIN = 'Админ';
const EXECUTOR = 'Исполнитель';
const CONSTRUCTOR = 'Конструктор';

/**
 * Типы файлов для проекта
 */
const PROJECT_ATTACHMENT_TYPE = {
	MODEL_3D: 'model_3d',
	MODEL_2D: 'model_2d',
	SKETCH: 'sketch',
	TECH_DOCUMENTATION: 'tech_documentation',
	ELECTRIC_SCHEME: 'electric_scheme',
}

export {
	ADMIN,
	EXECUTOR,
	CONSTRUCTOR,
	PROJECT_ATTACHMENT_TYPE,
}
