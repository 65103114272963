import { useNavigate } from 'react-router-dom';
import { useStore } from '../../store/hooks';

const useRedirectIfNoPermission = (permission) => {
  const navigate = useNavigate();

  if (!permission) {
    navigate(-1);
  }
};

export {
  useRedirectIfNoPermission,
};