import { useState } from "react";
import { Modal } from "react-bootstrap";
import { Button } from 'react-bootstrap';
import { useEffect } from "react";
import $api from "../../http/api.config";
import useAlert from "../../util/Alert";
import { useStore } from "../../store/hooks";
import { observer } from "mobx-react-lite";
import DatePicker from "react-datepicker";
import { getFullUserNameOrUsername } from "../../util/user";
import { ru } from "date-fns/locale";
import {EXECUTOR} from "../../util/constants";

const AddProjectModal = ({show, onHide, appendNewProject}) => {
	const authStore = useStore("authStore");
	const errorText = 'Поле не должно быть пустым';
	const [showLoader, setShowLoader] = useState(false);
	const [customers, setCustomers] = useState([]);
	const [users, setUsers] = useState([]);
	const [alert, setAlert] = useAlert();
	const [isLoading, setIsLoading] = useState(false);
	const [errors, setErrors] = useState({});
	const today = new Date();
	const [projectData, setProjectData] = useState({
		project_number: "",
		customer_project_number: "",
		name: "",
		customer: "",
		user: authStore.getUser?.id,
		start_date: today.toISOString().slice(0, 10),
		end_date: new Date(today.setDate(today.getDate() + 5)).toISOString().slice(0, 10),
	});

	useEffect(() => {
		// получаем список заказчиков
		$api.get('/api/v1/customers/').then((response) => {
            setCustomers(response.data);
			response.data.length && setProjectData((prev) => ({...prev, ['customer']: response.data[0].id}));
        }).finally(() => {
			(users && customers) && setIsLoading(true);
		});

		// получаем список работников
		const params = {
			page_size: 1000,
			is_active: 'True'
		}
		$api.get('/api/v1/users/', { params }).then((response) => {
			const responseData = response.data.results.filter(u =>
				u.groups.includes(EXECUTOR)
			);
			setUsers(responseData);
		}).finally(() => {
			(users && customers) && setIsLoading(true);
		});
	}, []);

	const handleChange = (field, value) => {
		setProjectData((projectData) => ({ ...projectData, [field]: value }));
	};
	
	const addNewProject = (e) => {
		e.preventDefault();
		
		if (checkRequiredFields()) return;

		setShowLoader(true);
		$api.post('/api/v1/projects/', projectData).then(response => {
			setAlert(response);
			appendNewProject(response.data);
			setProjectData(prev => ({
				...prev,
				['name']: "",
				['project_number']: "",
				['customer_project_number']: "",
			}));
		}).catch(err => {
			setAlert(err.response);
			setErrors(err.response.data);
		}).finally(() => {
			setShowLoader(false);
		});
	}

	const checkRequiredFields = () => {
		const errors = {};
		const hasNumberError = !projectData.project_number.trim();
		const hasNameError = !projectData.name.trim();
		
		hasNumberError && (errors.project_number = [errorText]);
		hasNameError && (errors.name = [errorText]);
		
		setErrors(errors);
		return Object.keys(errors).length !== 0;
	};

	return (
		<Modal  show={show}
				onHide={onHide}
				scrollable={true}>
			<Modal.Header closeButton>
				<Modal.Title>Добавить проект</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<form method='POST' id="addProjectFrom">
					<div className="row mb-2 text-start">
						<div className="col col-12">
							<label htmlFor="project_number">№ проекта</label>
						</div>
						<div className="col col-12">
							<input 
								className='form-control'
								disabled={!isLoading} 
								onChange={e => handleChange("project_number", e.target.value)}
								value={projectData.project_number}
							/>
							{ errors.project_number && <div className="invalid-feedback d-block">
								{ errors.project_number.map((e) => {
									return <p className="m-0">{e}</p>
								}) }
							</div> }
						</div>
					</div>
					<div className="row mb-2 text-start">
						<div className="col col-12">
							<label htmlFor="customer_project_number">№ проекта заказчика</label>
						</div>
						<div className="col col-12">
							<input 
								type="text" 
								className="form-control" 
								disabled={!isLoading} 
								value={projectData.customer_project_number}
								onChange={e => handleChange("customer_project_number", e.target.value)}
							/>
							{ errors.customer_project_number && <div className="invalid-feedback d-block">
								{ errors.customer_project_number.map((e) => {
									return <p className="m-0">{e}</p>
								}) }
							</div> }
						</div>
					</div>
					<div className="row mb-2 text-start">
						<div className="col col-12">
							<label htmlFor="name">Наименование</label>
						</div>
						<div className="col col-12">
							<input 
								type="text" 
								className='form-control'
								disabled={!isLoading}
								value={projectData.name}
								onChange={e => handleChange("name", e.target.value)}
							/>
							{ errors.name && <div className="invalid-feedback d-block">
								{ errors.name.map((e) => {
									return <p className="m-0">{e}</p>
								}) }
							</div> }
						</div>
					</div>
					<div className="row mb-2 text-start">
						<div className="col col-12">
							<label htmlFor="customer">Заказчик</label>
						</div>
						<div className="col col-12">
							<select 
								className="form-control" 
								disabled={!isLoading}
								onChange={e => handleChange("customer", e.target.value)}
							>
								{ customers && customers.map((item, key) => {
									return <option key={key} value={item.id}>{item.name}</option>
								}) }
							</select>
							{ errors.customer && <div className="invalid-feedback d-block">
								{ errors.customer.map((e) => {
									return <p className="m-0">{e}</p>
								}) }
							</div> }
						</div>
					</div>
					<div className="row mb-2 text-start">
						<div className="col col-12">
							<label htmlFor="user">Исполнитель</label>
						</div>
						<div className="col col-12">
							<select 
								className="form-control"
								disabled={!isLoading} 
								value={projectData.user || authStore.getUser?.id}
								onChange={e => handleChange("user", e.target.value)}
							>
								{ users && users.map((item, key) => {
									return <option key={key} value={item.id}>{getFullUserNameOrUsername(users, item.id)}</option>
								}) }
							</select>
							{ errors.user && <div className="invalid-feedback d-block">
								{ errors.user.map((e) => {
									return <p className="m-0">{e}</p>
								}) }
							</div> }
						</div>
					</div>
					<div className="row mb-2 text-start">
						<div className="col col-12">
							<label htmlFor="start_date">Дата начала разработки</label>
						</div>
						<div className="col col-12">
							<DatePicker
								selected={projectData.start_date}
								onChange={(date) => handleChange("start_date", new Date(date).toISOString().slice(0, 10))}
								className="form-control"
								disabled={!isLoading}
								dateFormat="dd.MM.yyyy"
								locale={ru}
							/>
							{ errors.start_date && <div className="invalid-feedback d-block">
								{ errors.start_date.map((e) => {
									return <p className="m-0">{e}</p>
								}) }
							</div> }
						</div>
					</div>
					<div className="row mb-2 text-start">
						<div className="col col-12">
							<label htmlFor="end_date">Дата окончания разработки</label>
						</div>
						<div className="col col-12">
							<DatePicker
								selected={projectData.end_date}
								onChange={(date) => handleChange("end_date", new Date(date).toISOString().slice(0, 10))}
								className="form-control"
								disabled={!isLoading}
								dateFormat="dd.MM.yyyy"
								locale={ru}
								/>
							{ errors.end_date && <div className="invalid-feedback d-block">
								{ errors.end_date.map((e) => {
									return <p className="m-0">{e}</p>
								}) }
							</div> }
						</div>
					</div>
				</form>
				{ alert && <div className="row">
                    <div className="col">
                        { alert }
                    </div>
                </div> }
			</Modal.Body>
			<Modal.Footer className="d-flex justify-content-between">
				{ showLoader ?
					<div className="filter_loader_box">
						<div id="loader-4">
							<span></span>
							<span></span>
							<span></span>
						</div>
					</div>
					:
					<div></div>
				}
				<div className="buttons">
					<Button variant="secondary" onClick={onHide}>
						Отмена
					</Button>
					<Button 
						variant="primary" 
						className='ms-2' 
						onClick={addNewProject}
						disabled={showLoader}
						>
						Добавить
					</Button>
				</div>
			</Modal.Footer>
		</Modal> 
	)
}

export default observer(AddProjectModal);