import React, { useEffect, useState } from "react";
import { Spinner, Card, Alert, Badge } from "react-bootstrap";
import $api from '../../../http/api.config';
import { getCorrectWordForm } from '../../../util/functions';
import {Link} from "react-router-dom";
import {useStore} from "../../../store/hooks";

const API_URL = "/api/v1/projects/subtasks/my/";
const PREFIX = "subtasks_module";

const Subtasks = ({ userIds = [] }) => {
    const authStore = useStore('authStore');
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [allSubtasks, setAllSubtasks] = useState(
      localStorage.getItem(PREFIX + '_all_subtasks') ? eval(localStorage.getItem(PREFIX + '_all_subtasks')) : true
    );

    useEffect(() => {
        fetchSubtasks();
    }, []);

    useEffect(() => {
        fetchSubtasks();
    }, [allSubtasks]);

    const fetchSubtasks = async (ids = userIds) => {
        setLoading(true);
        setError(null);

        let params = {};
        ids.length && (params['users_ids'] = authStore.user_id);
        !allSubtasks && (params['only_unfinished'] = true);

        try {
            const response = await $api.get(API_URL, {
                params: params,
            });
            setProjects(response.data);
        } catch (err) {
            setError("Ошибка загрузки данных.");
        } finally {
            setLoading(false);
        }
    };

    const handleToggle = (button) => {
        localStorage.setItem(PREFIX + '_all_subtasks', button);
        setAllSubtasks(button);
    };

    return (
        <div className="mt-4 text-start">
            <h3 className="mb-4 ms-1">Мои подзадачи</h3>
            <div className="mb-3">
                <div className="btn-group" role="group" aria-label="Basic outlined example">
                    <button
                      type="button"
                      className={`btn btn-outline-success ${allSubtasks ? "active" : ""}`}
                      onClick={() => handleToggle(true)}
                    >
                        Все
                    </button>
                    <button
                      type="button"
                      className={`btn btn-outline-success ${!allSubtasks ? "active" : ""}`}
                      onClick={() => handleToggle(false)}
                    >
                        Невыполненные
                    </button>
                </div>
            </div>

            {loading &&
              <div className="text-center my-3">
                  <Spinner animation="border"/>
              </div>
            }
            {error && <Alert variant="danger">{error}</Alert>}

            {!loading && projects.length === 0 && <Alert variant="info">Нет данных</Alert>}

            {projects.map((project) => (
              <Card key={project.project_number} className="mb-3 shadow-sm">
                  <Card.Body>
                        <div className="d-flex justify-content-between align-items-center p-3">
                            <div>
                                <Card.Title>
                                    <Link
                                      className='btn btn-dark text-start'
                                      to={'/projects/' + project.id + '/'}>
                                        {project.name}
                                    </Link>
                                </Card.Title>
                                <Card.Subtitle className="text-muted">
                                    <p className="mb-1 mt-3">
                                        Номер: {project.project_number}
                                    </p>
                                    <p className="mb-0">
                                        Номер заказчика: {project.customer_project_number || "—"}
                                    </p>
                                </Card.Subtitle>
                            </div>
                            <div className="py-1 px-2 d-md-block d-none"
                                 style={{background: "#757f8f", color: "#fff", borderRadius: "6px"}}>
                                {project.subtasks.length} {getCorrectWordForm(project.subtasks.length, ['задача', 'задачи', 'задач'])}
                            </div>
                        </div>

                      <div>
                          {project.subtasks.length > 0 ? (
                            project.subtasks.map((subtask) => (
                              <div key={subtask.id} className="p-3 m-2 border rounded bg-light">
                                        <div className="
                                            d-flex
                                            flex-column-reverse
                                            flex-md-row
                                            align-items-start
                                            align-items-md-center
                                            justify-content-between
                                            ">
                                            <strong>{subtask.message}</strong>
                                            <Badge bg={subtask.done ? "success" : "warning"} className="mb-1 mb-md-0">
                                                {subtask.done ? "Выполнено" : "В работе"}
                                            </Badge>
                                        </div>
                                        <p className="mb-1 text-muted">{subtask.comment || "Без комментария"}</p>
                                        <div className="text-muted small">
                                            {subtask.start_date ? subtask.start_date.split('-').reverse().join('.') : "Не указано"} — {subtask.end_date ? subtask.end_date.split('-').reverse().join('.') : "Не указано"}
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <Alert variant="warning" className="mt-2">
                                    Нет подзадач
                                </Alert>
                            )}
                        </div>
                    </Card.Body>
                </Card>
            ))}
        </div>
    );
};

export default Subtasks;
