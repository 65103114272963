import { observer } from 'mobx-react-lite';
import '../../../static/scss/main.scss';
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useStore} from "../../../store/hooks";
import Button from "react-bootstrap/Button";
import Loader from "../../loader/Loader";
import {useParams} from "react-router-dom";
import $api from "../../../http/api.config";
import useAlert from "../../../util/Alert";


const UserDetail = observer(() => {
	const authStore = useStore('authStore');
    const baseStore = useStore('baseStore');
    const params = useParams();
    const [isLoaded, setIsLoaded] = useState(false);
    const [user, setUser] = useState(null);
    const [errors, setErrors] = useState({});
	const [showLoader, setShowLoader] = useState(false);
	const [alert, setAlert] = useAlert();
	const navigate = useNavigate();
	const userId = Number(params.id);


    useEffect(() => {
		// Вернуть пользователя на предыдущую страницу, если пользователь не админ или смотрит другого пользователя
		const currentUserId = authStore.getUser?.id;
		if (
			currentUserId !== userId && 
			!authStore.is_superuser && 
			(
				!authStore.user?.is_staff &&
				!authStore.permissions?.users?.canAdd &&
				!authStore.permissions?.users?.canDelete
			)
		) {
			navigate(-1);
		} else {
			getUser();
		}
    }, []);

	useEffect(() => {
		const currentUserId = authStore.getUser?.id;
		if (currentUserId === userId) {
			getUser();
		}
	}, [userId]);

	const getUser = () => {
		setIsLoaded(false);
		$api.get(`/api/v1/users/${userId}/`).then(response => {
			setUser(response.data);
		}).finally(() => {
			setIsLoaded(true);
		});
	}

    const updateUser = (e) => {
		e.preventDefault();
		const errors = validateUser();
		if(Object.keys(errors).length === 0 && userId) {
			setShowLoader(true);
			$api.put(`/api/v1/users/${userId}/`, user).then(response => {
			    setAlert(response);
			}).catch(err => {
			    setAlert(err.response);
				setErrors(err.response.data);
			}).finally(() => {
			    setShowLoader(false);
			});
		}
	}

    const validateUser = () => {
		const errors = {};
		// Проверка логина
		if (!user.username || user.username.trim() === "") {
			errors.username = "Логин не может быть пустым.";
		}

		// Проверка email
		if (!user.email || user.email.trim() === "") {
			errors.email = "Email не может быть пустым.";
		}

		setErrors(errors);
		return errors;
	};

	const handleChange = (e) => {
		const { name, type, checked, value } = e.target;
		const newValue = type === 'checkbox' ? checked : value;
        setUser((prev) => ({
            ...prev,
            [name]: newValue,
        }));
	}

    return (
        <>
			{isLoaded ?
				<div className="detail-page">
					<div className="container-fluid text-start">
						<div className="bg-white rounded">
							<div className="row">
								<div className="col text-center mb-4">
									<h2>
										{authStore.getUser?.id === userId ? 'Мой профиль' : 'Профиль пользователя'}
									</h2>
								</div>
							</div>
							<div className="content">
                                <form method='POST' id='project-form'>
                                    <div className="row">
                                        <div className="col">
                                            <p className='label'>Логин:</p>
                                        </div>
                                        <div className="col">
											<span className='font-weight-bold'>
												<input name="username"
                                                       className="form-control"
                                                       type="text"
                                                       placeholder="Заполните поле"
													   onChange={handleChange}
                                                       value={user.username}/>
												{errors.username && <span className="d-block ms-1 mt-1 text-danger small">{errors.username}</span>}
											</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <p className='label'>Имя:</p>
                                        </div>
                                        <div className="col">
											<span className='font-weight-bold'>
												<input name="first_name"
                                                       className="form-control"
                                                       type="text"
                                                       placeholder="Заполните поле"
													   onChange={handleChange}
                                                       value={user.first_name}/>
											</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <p className='label'>Фамилия:</p>
                                        </div>
                                        <div className="col">
											<span className='font-weight-bold'>
												<input name="last_name"
                                                       className="form-control"
                                                       type="text"
                                                       placeholder="Заполните поле"
													   onChange={handleChange}
                                                       value={user.last_name}/>
											</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <p className='label'>E-mail:</p>
                                        </div>
                                        <div className="col">
											<span className='font-weight-bold'>
												<input name="email"
                                                       className="form-control"
                                                       type="text"
                                                       placeholder="Заполните поле"
													   onChange={handleChange}
                                                       value={user.email}/>
											</span>
											{errors.email && <span className="d-block ms-1 mt-1 text-danger small">{errors.email}</span>}
                                        </div>
                                    </div>
									<div className="row">
										<div className="col">
											<p className="label">
												Получать уведомления:
											</p>
										</div>
										<div className="col">
											<div className="form-switch">
												<input
													onChange={handleChange}
													name="receive_emails"
													className="form-check-input"
													type="checkbox"
													checked={user.receive_emails}
													/>
											</div>
										</div>
									</div>
									{alert && <div className="row">
										<div className="col">
											{alert}
										</div>
									</div>}
									<div className='row mt-3'>
										<div className="col d-flex justify-content-start">
											{showLoader ?
												<div className="filter_loader_box m-0">
													<div id="loader-4">
														<span></span>
														<span></span>
														<span></span>
													</div>
												</div>
												:
												<div></div>
											}
										</div>
										<div className="col d-flex justify-content-end">
											<Button onClick={updateUser}
													className='button-outline'
                                                    variant='outline-dark'>
                                                Сохранить изменения
                                            </Button>
                                        </div>
                                    </div>
                                </form>

                                <br/>
                                <br/>
                            </div>
                        </div>
                    </div>
                </div>
                : <Loader/>
            }

        </>
    )
});

export default UserDetail;