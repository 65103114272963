import { BasePermissions } from "../permissions";
import PermissionModelTypes from "../types";

class ProjectPermissions extends BasePermissions {
	constructor(user) {
		super(user, PermissionModelTypes.PROJECT);
	}

	canChange(): boolean {
		return super.canChange() && !this.isGuest();
	}

	canAdd(): boolean {
		return super.canAdd() && !this.isGuest();
	}

	canDelete(): boolean {
		return super.canDelete() && !this.isGuest();
	}
}

class SubtaskPermissions extends BasePermissions {
	constructor(user) {
		super(user, PermissionModelTypes.SUBTASK);
	}

	canChange(): boolean {
		return super.canChange();
	}

	canAdd(): boolean {
		return super.canAdd();
	}

	canDelete(): boolean {
		return super.canDelete() && !this.isGuest();
	}
}

export {
	ProjectPermissions,
	SubtaskPermissions,
}