import moment from 'moment';
import 'moment/locale/ru';
import {colors, finishedNotAtEndDateBadge} from './variables';
import {faFontAwesome} from "@fortawesome/free-solid-svg-icons";

moment.locale('ru');

// todayLine plugin
const todayLine = {
    id: 'todayLine',
    beforeDatasetsDraw(chart, args, options, cancelable) {
        const { ctx, data, chartArea: { top, bottom, left, right }, scales: { x, y } } = chart;

        ctx.save();

        ctx.beginPath();
        ctx.lineWidth = 3;
        ctx.strokeStyle = 'rgba(120, 102, 102, 1)';
        ctx.setLineDash([6, 4]);
        ctx.moveTo(x.getPixelForValue(new Date()), top);
        ctx.lineTo(x.getPixelForValue(new Date()), bottom);
        ctx.stroke();
        ctx.restore();

        ctx.setLineDash([]); // восстанавливаем линии по бокам
        
        // стрелочкка сверху
        ctx.beginPath();
        ctx.lineWidth = 1;
        ctx.strokeStyle = 'rgba(120, 102, 102, 1)';
        ctx.fillStyle = 'rgba(120, 102, 102, 1)';
        ctx.moveTo(x.getPixelForValue(new Date()), top + 4);
        ctx.lineTo(x.getPixelForValue(new Date()) - 6, top - 6);
        ctx.lineTo(x.getPixelForValue(new Date()) + 6, top - 6);
        ctx.closePath();
        ctx.stroke();
        ctx.fill();
        ctx.restore();

        ctx.font = 'bold 12px sans-serif';
        ctx.fillStyle = 'rgba(120, 102, 102, 1)';
        ctx.textAlign = 'center';
        ctx.fillText(`Сегодня ${moment().format('D MMM')}`, x.getPixelForValue(new Date()), bottom + 15);
        ctx.restore();
    }
}

// status plugin
const statusPlugin = {
    id: 'status',
    afterDatasetsDraw(chart, args, cancelable) {
        const { ctx, data, options, chartArea: { top, bottom, left, right }, scales: { x, y } } = chart;
        const icons = ['\uf00d', '\uf110', '\uf00c'];
        const angle = Math.PI / 180;
        const paddingRight = options.layout.padding.right;

        ctx.save();
        ctx.font = 'bolder 12px FontAwesome';

        ctx.textBaseline = 'middle';
        ctx.textAlign = 'center';
        data.datasets[0].data.forEach((datapoint, index) => {
            if(y.getPixelForValue(index) > top && y.getPixelForValue(index) < bottom) {
                ctx.beginPath();
                ctx.fillStyle = colors[datapoint.status];
                ctx.arc(right + (paddingRight / 2), y.getPixelForValue(index), 12, 0, angle * 360, false);
                ctx.closePath();
                ctx.fill();
                ctx.fillStyle = 'white';
                ctx.fillText(icons[datapoint.status], right + 50, y.getPixelForValue(index));
            }
        })

        ctx.font = 'bolder 12px sans-serif';
        ctx.fillStyle = 'black';
        ctx.fillText('Статус', right + (paddingRight / 2), top - 15);
        ctx.restore();
    }
}

// weekend plugin
const weekendPlugin = {
    id: 'weekend',
    beforeDatasetsDraw(chart, args, pluginOptions) {
        const { ctx, chartArea: { top, height }, scales: { x } } = chart;

        ctx.save();

        // Получаем диапазон шкалы x
        const startValue = x.min; // Начальное значение оси x
        const endValue = x.max;   // Конечное значение оси x

        // Устанавливаем текущую дату для итерации от начала до конца видимого диапазона
        let currentDate = new Date(startValue);

        // Цикл по дням, неделям или другим периодам
        while (currentDate <= new Date(endValue)) {
            const day = currentDate.getDay(); // День недели

            if (day === 6 || day === 0) { // Проверка: Суббота (6) или Воскресенье (0)
                const startPixel = x.getPixelForValue(currentDate);
                const nextDate = new Date(currentDate);
                nextDate.setDate(currentDate.getDate() + 1); // Следующий день
                const endPixel = x.getPixelForValue(nextDate);

                ctx.fillStyle = pluginOptions.weekendColor;
                ctx.fillRect(startPixel, top, endPixel - startPixel, height);
            }

            // Переход к следующему дню
            currentDate.setDate(currentDate.getDate() + 1);
        }

        ctx.restore();

    }
}

const textOnBarPlugin = {
    id: 'textInsideBars',
    afterDatasetsDraw(chart) {
        const { ctx, chartArea, data, options } = chart;
        const datasets = data.datasets;

        if (!datasets.length || !datasets[0].data.length) return;

        datasets.forEach((dataset, datasetIndex) => {
            dataset.data.forEach((dataPoint, index) => {
                const meta = chart.getDatasetMeta(datasetIndex);
                const element = meta.data[index];

                const { x, y, width, height } = element.getProps(["x", "y", "width", "height"]);

                const maxWidth = chartArea.right - chartArea.left - 100; // Ограничиваем ширину текста

                ctx.save();
                ctx.font = "12px Arial";
                ctx.textAlign = "right";
                ctx.textBaseline = "middle";

                // Текст - название проекта
                const projectName = dataset.label || (dataPoint?.data?.name || "");

                // Обрезаем текст, если он не помещается в пределах ширины
                let displayText = projectName;
                if (ctx.measureText(projectName).width > maxWidth) {
                    while (ctx.measureText(displayText + "...").width > maxWidth && displayText.length > 0) {
                        displayText = displayText.slice(0, -1);
                    }
                    displayText += "...";
                }

                ctx.fillStyle = "black";
                ctx.fillText(displayText, chartArea.right - 10, y);

                // статус завершен во время или нет
                if(dataset?.label === "") {
                    const end_date = dataPoint.x[1];
                    const finishedAt = dataPoint?.data?.finished_at;
                    if ((finishedAt && end_date) && (end_date < finishedAt)) {
                        ctx.font = "bold 9px Arial, sans-serif";
                        ctx.fillText(finishedNotAtEndDateBadge, x - 7, y);
                    }
                }

                ctx.restore();
            });
        });
    }
};

// no data plugin
const noDataPlugin = {
    id: "noData",
    afterDatasetsDraw: ((chart, args, plugins) => {
        const { ctx, data, chartArea: {top, bottom, left, right, width, height }} = chart;

        ctx.save();
        if(data.datasets[0].data.length === 0) {
            ctx.font = "bold 20px sans-serif"
            ctx.fillStyle = 'black';
            ctx.textAlign = 'center';
            ctx.fillText(chart.options.plugins.noData.message, left + width / 2, top + height / 2);
        }
    })
}

export {
    todayLine, 
    statusPlugin, 
    weekendPlugin,
    textOnBarPlugin,
    noDataPlugin
}