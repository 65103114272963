const colors = [
    'rgba(220, 53, 69, 1)',
    'rgba(255, 159, 64, 1)',
    'rgba(25, 135, 84, 1)',
    'rgba(101, 152, 228, 1)',
    'rgba(108, 117, 125, 1)',
];

const periods = {
    year: 'год',
    halfYear: 'полгода',
    quarter: 'квартал',
    month: 'месяц',
    day: 'день',
};

const finishedNotAtEndDateBadge = '🔴';

export { 
    colors, 
    periods, 
    finishedNotAtEndDateBadge 
}