import {observer} from "mobx-react-lite";
import {useEffect, useState, useRef} from "react";
import {Bar} from "react-chartjs-2";
import "chartjs-adapter-date-fns";
import {ru} from "date-fns/locale";
import DatePicker from "react-datepicker";
import moment from "moment";
import {
    todayLine,
    weekendPlugin,
    noDataPlugin,
    textOnBarPlugin,
} from "./plugins";
import {colors, periods, finishedNotAtEndDateBadge} from "./variables";
import {getDates, getPeriod, prepareDate} from "./utils";
import "./gantt-chart.scss";

import {
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,
    TimeScale,
} from "chart.js";
import $api from "../../../http/api.config";
import { useStore } from "../../../store/hooks";
import { toJS } from "mobx";
import { statusOptions } from "../../../util/choices/ProjectOptions";
import { QuestionSquareFill } from "react-bootstrap-icons";


ChartJS.register(
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,
    TimeScale,
);

moment.locale("ru");

const YES = 'Выполнено';
const NO = 'Не выполнено';

const GanttChart = observer(() => {
    const baseStore = useStore('baseStore');
    const [dataset, setDataset] = useState([]);

    const {adjustedStartDate, adjustedEndDate} = getDates();
    const [startDate, setStartDate] = useState(adjustedStartDate);
    const [endDate, setEndDate] = useState(adjustedEndDate);
    const [selectedPeriod, setSelectedPeriod] = useState(localStorage.getItem('chart_period') || "month");
    const chartRef = useRef(null);
    const [canvasHeight, setCanvasHeight] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isColorInformationVisible, setIsColorInformationVisible] = useState(false);

    console.log(startDate)
    console.log(endDate)

    useEffect(() => {
        updateChart();
    }, [startDate, endDate, baseStore.projectsFilterSet, baseStore.projectSearchFieldValue]);

    useEffect(() => {
        // localStorage.setItem('chart_start_date', startDate);
        // localStorage.setItem('chart_end_date', endDate);
        console.log(startDate)
        console.log(endDate)
    }, [startDate, endDate]);

    useEffect(() => {
        const minHeight = 250;
        const height = 
            dataset.length > 50 ? 
            dataset.length * 130 :
            ( dataset.length * 130 >= minHeight ?
                dataset.length * 130 : minHeight
            );
        setCanvasHeight(height);
    }, [dataset]);

    const updateChart = () => {
        setIsLoading(true);
        const searchUrl = `/api/v1/projects/chart-data`;
        let filterParameters = toJS(baseStore.projectsFilterSet);
        filterParameters['start_date'] = new Date(startDate).toISOString().slice(0, 10);
        filterParameters['end_date'] = new Date(endDate).toISOString().slice(0, 10);
        baseStore.projectSearchFieldValue && (filterParameters['project_number_contains'] = baseStore.projectSearchFieldValue);
        $api.get(searchUrl, {
          params: filterParameters
        }).then((response) => {
            setDataset(response.data);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const plugins = [todayLine, weekendPlugin, noDataPlugin, textOnBarPlugin];

    const handlePeriodChange = (event) => {
        const value = event.target.value;
        setSelectedPeriod(value);
        getPeriod(event.target.value, setStartDate, setEndDate);
        localStorage.setItem('chart_period', value);
    };

    function isSegmentCompleted(item, keys) {
        return keys.every((key) => item[key]);
    }

    function calculateSegmentPosition(startDate, endDate, fraction) {
        const start = new Date(startDate);
        const end = new Date(endDate);
        const diffTime = Math.abs(end - start);
        const segmentEnd = new Date(start.getTime() + diffTime * fraction);
        return segmentEnd.toISOString();
    }

    function determineColor(item, keys) {
        const danger = colors[0];
        const warning = colors[1];
        const success = colors[2];
        const inactive = colors[4];
        const frozen = colors[3];

        const status = item.db_status;
        if(status === 'UNDER_CONSIGNMENT') {
            return inactive;
        } else if(status === 'FROZEN') {
            return frozen;
        }

        const allDone = keys.every(key => item[key]);
        const deadline = item.end_date ? new Date(item.end_date) : null;

        // Если дедлайн
        if (deadline) {
            const now = new Date();
            const allDoneBeforeDeadline = keys.every(key => item[key + "_date"] && new Date(item[key + "_date"]) <= deadline);

            if (allDoneBeforeDeadline) return success;
            if (now < deadline) return warning;
            return danger;
        }

        return allDone ? success : warning;
    }

    const data = {
        datasets: [
            {
                label: "",
                data: dataset.map((item) => {
                    return {
                        x: [item.dates[0], item.dates[1]],
                        y: item.project_number,
                        data: item,
                    };
                }),
                backgroundColor: (ctx) => {
                    return colors[ctx.raw.data.status]
                },
                stack: "project",
            },
            {
                label: "Конструкторская документация",
                data: dataset.map((item) => {
                    return {
                        x: [item.dates[0], calculateSegmentPosition(item.dates[0], item.dates[1], 1 / 3)],
                        y: item.project_number,
                        data: item,
                        value: isSegmentCompleted(item, ["model_3d_done", "model_2d_done", "sketch_done", "send_done"]) ? 1 : 0,
                    };
                }),
                backgroundColor: (ctx) => {
                    let color = determineColor(ctx.raw.data, ["model_3d_done", "model_2d_done", "sketch_done", "send_done"]);
                    return determineColor(ctx.raw.data, ["model_3d_done", "model_2d_done", "sketch_done", "send_done"])
                },
                stack: "project1",
            },
            {
                label: "Техническая документация",
                data: dataset.map((item) => {
                    return {
                        x: [calculateSegmentPosition(item.dates[0], item.dates[1], 1 / 3), calculateSegmentPosition(item.dates[0], item.dates[1], 2 / 3)],
                        y: item.project_number,
                        data: item,
                        value: item.tech_documentation_done ? 1 : 0,
                    };
                }),
                backgroundColor: (ctx) => {
                    return determineColor(ctx.raw.data, ["tech_documentation_done"])
                },
                stack: "project2",
            },
            {
                label: "Электрическая схема",
                data: dataset.map((item) => {
                    return {
                        x: [calculateSegmentPosition(item.dates[0], item.dates[1], 2 / 3), item.dates[1]],
                        y: item.project_number,
                        data: item,
                        value: item.electric_scheme_done ? 1 : 0,
                    };
                }),
                backgroundColor: (ctx) => {
                    return determineColor(ctx.raw.data, ["electric_scheme_done"]);
                },
                stack: "project3",
            },
        ],
    };

    const options = {
      parsing: {
          xAxisKey: "x",
          yAxisKey: "y",
      },
      maintainAspectRatio: false,
      responsive: true,
      layout: {
          padding: {
              left: 10,
              right: 10,
              bottom: 20,
          },
      },
      indexAxis: "y",
      scales: {
          x: {
              position: "top",
              type: "time",
              time: {
                  unit: "day",
                  displayFormats: {
                      day: "d.MM",
                  },
                  tooltipFormat: "d.MM.yyyy HH:mm",
              },
              min: moment(startDate).subtract(1, "days").toDate(),
              max: moment(endDate).add(1, "days").toDate(),
              stacked: true,
          },
          y: {
              stacked: true,
          },
      },
      elements: {
          bar: {
              // barThickness: 25,
              // borderRadius: 15,
          },
      },
      plugins: {
          legend: {
                display: false,
            },
          weekend: {
                weekendColor: "rgba(245, 191, 200, 0.3)",
            },
          tooltip: {
                mode: "nearest",
                displayColors: false,
                callbacks: {
                    label: (context) => {
                        return null;
                    },
                    title: (ctx) => {
                        if (!ctx.length) return ""; // Защита от ошибок

                        const label = ctx[0].dataset.label;

                        if(label === "Конструкторская документация") {
                            return [
                                `Проект: ${ctx[0].raw.data.name}`,
                                `3д модель: ${ctx[0].raw.data.model_3d_done ? YES : NO} (${ctx[0].raw.data.model_3d_done_date ? prepareDate(ctx[0].raw.data.model_3d_done_date) : 'Дата не указана'})`,
                                `2д чертежи: ${ctx[0].raw.data.model_2d_done ? YES : NO} (${ctx[0].raw.data.model_2d_done_date ? prepareDate(ctx[0].raw.data.model_2d_done_date) : 'Дата не указана'})`,
                                `Эскиз: ${ctx[0].raw.data.sketch_done ? YES : NO} (${ctx[0].raw.data.sketch_done_date ? prepareDate(ctx[0].raw.data.sketch_done_date) : 'Дата не указана'})`,
                                `Отгружен: ${ctx[0].raw.data.send_done ? YES : NO} (${ctx[0].raw.data.send_done_date ? prepareDate(ctx[0].raw.data.send_done_date) : 'Дата не указана'})`,
                            ];
                        } else if(label === "Техническая документация") {
                            return [
                                `Проект: ${ctx[0].raw.data.name}`,
                                `Техническая документация: ${ctx[0].raw.data.tech_documentation_done ? YES : NO} (${ctx[0].raw.data.tech_documentation_done_date ? prepareDate(ctx[0].raw.data.tech_documentation_done_date) : 'Дата не указана'})`,
                            ];
                        } else if(label === "Электрическая схема") {
                            return [
                                `Проект: ${ctx[0].raw.data.name}`,
                                `Электрическая схема: ${ctx[0].raw.data.electric_scheme_done ? YES : NO} (${ctx[0].raw.data.electric_scheme_done_date ? prepareDate(ctx[0].raw.data.electric_scheme_done_date) : 'Дата не указана'})`,
                            ];
                        } else {
                            const startDate = new Date(ctx[0].raw.x[0]);
                            const endDate = new Date(ctx[0].raw.data.estimated_end_date);
                            const formattedStartDate = startDate.toLocaleString("ru", {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                            });
                            const formattedEndDate = endDate.toLocaleString("ru", {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                            });
                            let finished_at = ctx[0].raw.data.finished_at;
                            if(finished_at) {
                                finished_at = new Date(finished_at).toLocaleString("ru", {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                });
                            }
                            return [
                                `Проект: ${ctx[0].raw.data.name}`,
                                `Исполнитель: ${ctx[0].raw.data.employee}`,
                                `Организация: ${ctx[0].raw.data.organization}`,
                                `Даты: ${formattedStartDate} - ${formattedEndDate}`,
                                `Статус: ${statusOptions.find(e => e.value === ctx[0].raw.data.db_status)?.name}`,
                                `Дата завершения (последняя смена статуса на "Завершено"): ${finished_at || '-'}`,
                            ];
                        }

                    },
                },
            },
          noData: {
            message: isLoading ? "Идет поиск..." : "Нет подходящих проектов"
          }
      },
    };

    return (
        <div className="gantt-chart">
            <div className="chart-configuration">
                <div className="row pb-3 pt-3">
                    <div className="col">
                        {/* Заголовок с иконкой вопроса */}
                        <h6 className="d-flex align-items-center">
                            Обозначения цветов
                            <QuestionSquareFill
                              className="ms-2 text-secondary"
                              style={{cursor: "pointer", fontSize: "1.2rem"}}
                              onClick={() => setIsColorInformationVisible(!isColorInformationVisible)}
                            />
                        </h6>

                        {/* Блок легенды */}
                        {isColorInformationVisible && (
                          <div className="mt-2">
                              <p className="mb-0 text-start small d-flex align-items-center">
                                  <div
                                    style={{
                                        background: `${colors[0]}`,
                                        borderRadius: "5px",
                                        width: "45px",
                                        height: "18px"
                                    }}
                                    className="d-inline-block me-1"
                                  ></div>
                                  - срочно
                              </p>
                              <p className="mb-0 text-start small d-flex align-items-center">
                                  <div
                                    style={{
                                        background: `${colors[1]}`,
                                        borderRadius: "5px",
                                        width: "45px",
                                        height: "18px"
                                    }}
                                    className="d-inline-block me-1"
                                  ></div>
                                  - в работе
                              </p>
                              <p className="mb-0 text-start small d-flex align-items-center">
                                  <div
                                    style={{
                                        background: `${colors[2]}`,
                                        borderRadius: "5px",
                                        width: "45px",
                                        height: "18px"
                                    }}
                                    className="d-inline-block me-1"
                                  ></div>
                                  - завершен
                              </p>
                              <p className="mb-0 text-start small d-flex align-items-center">
                                  <div
                                    style={{
                                        background: `${colors[3]}`,
                                        borderRadius: "5px",
                                        width: "45px",
                                        height: "18px"
                                    }}
                                    className="d-inline-block me-1"
                                  ></div>
                                  - заморожен
                              </p>
                              <p className="mb-0 text-start small d-flex align-items-center">
                                  <div
                                    style={{
                                        background: `${colors[4]}`,
                                        borderRadius: "5px",
                                        width: "45px",
                                        height: "18px"
                                    }}
                                    className="d-inline-block me-1"
                                  ></div>
                                  - на рассмотрении
                              </p>
                              <p className="mb-0 d-flex justify-content-start">
                                  <small>
                                      <div
                                        style={{
                                            padding: "2px 10px 2px 20px",
                                            borderRadius: "5px",
                                            fontSize: "10px"
                                        }}
                                        className="bg-success d-inline-block me-1"
                                      >
                                          {finishedNotAtEndDateBadge}
                                      </div>
                                      - завершен позже срока
                                  </small>
                              </p>
                          </div>
                        )}
                    </div>
                </div>
                <div className="row pb-3">
                    <div className="col col-3 d-flex align-items-center">
                        <div>
                            <select
                              id="period-select"
                              value={selectedPeriod}
                              onChange={handlePeriodChange}
                              className="form-select"
                            >
                                {Object.entries(periods).map(([key, label]) => (
                                  <option key={key} value={key}>
                                      {label}
                                  </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="col">
                        <div className="raw">
                            <div className="col d-flex align-items-center justify-content-end">
                                <div className="datepicker d-flex flex-column me-1">
                                    <DatePicker
                                      className="form-control"
                                      selected={startDate}
                                      onChange={date => setStartDate(new Date(date.setHours(date.getHours() + 3)))}
                                      dateFormat="dd.MM.yyyy"
                                      locale={ru}
                                      calendarStartDay={1}
                                      popperPlacement="bottom-end"
                                    />
                                    <span>начальная дата</span>
                                </div>
                                <div className="datepicker d-flex flex-column">
                                    <DatePicker
                                      className="form-control"
                                      selected={endDate}
                                      onChange={date => setEndDate(new Date(date.setHours(date.getHours() + 3)))}
                                      dateFormat="dd.MM.yyyy"
                                      locale={ru}
                                      calendarStartDay={1}
                                      popperPlacement="bottom-end"
                                    />
                                    <span>конечная дата</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row chart">
                <div className="canvas_wrapper" style={canvasHeight ? {height: canvasHeight + "px"} : {}}>
                    {dataset.length > 0 &&
                      <Bar ref={chartRef} data={data} options={options} plugins={plugins}/>
                    }
                </div>
            </div>
        </div>
    );
});

export default GanttChart;
