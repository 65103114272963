const initializeFilterData = () => {
    let data = {};
    if(localStorage.getItem('filter_user')) {
        data['user'] = localStorage.getItem('filter_user');
    } 
    if(localStorage.getItem('filter_status')) {
        data['status'] = localStorage.getItem('filter_status');
    } 
    if(localStorage.getItem('filter_payment')) {
        data['payment'] = localStorage.getItem('filter_payment');
    } 
    if(localStorage.getItem('filter_customer')) {
        data['customer'] = localStorage.getItem('filter_customer');
    } 
    if(localStorage.getItem('filter_user')) {
        data['user'] = localStorage.getItem('filter_user');
    } 
    if(localStorage.getItem('filter_page_size')) {
        data['page_size'] = localStorage.getItem('filter_page_size');
    }

    // Заказчики
    if(localStorage.getItem('filter_customers_exclude_mode')) {
        data['customers_exclude_mode'] = localStorage.getItem('filter_customers_exclude_mode');
    }
    if(localStorage.getItem('filter_selected_customers')) {
        const stored = localStorage.getItem("filter_selected_customers");
        stored && (data['customers'] = stored);
    }

    // Статусы
    if(localStorage.getItem('filter_statuses_exclude_mode')) {
        data['statuses_exclude_mode'] = localStorage.getItem('filter_statuses_exclude_mode');
    }
    if(localStorage.getItem('filter_selected_statuses')) {
        const stored = localStorage.getItem("filter_selected_statuses");
        stored && (data['status'] = stored);
    }

    // Исполнители
    if(localStorage.getItem('filter_users_exclude_mode')) {
        data['users_exclude_mode'] = localStorage.getItem('filter_users_exclude_mode');
    }
    if(localStorage.getItem('filter_selected_users')) {
        const stored = localStorage.getItem("filter_selected_users");
        stored && (data['user'] = stored);
    }

    // Оплата
    if(localStorage.getItem('filter_payments_exclude_mode')) {
        data['payments_exclude_mode'] = localStorage.getItem('filter_payments_exclude_mode');
    }
    if(localStorage.getItem('filter_selected_payments')) {
        const stored = localStorage.getItem("filter_selected_payments");
        stored && (data['payment'] = stored);
    }

    return data;
}

export { initializeFilterData };