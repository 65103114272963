import { useEffect } from 'react';
import { useState } from 'react';
import { observer } from "mobx-react-lite";
import {ArrowDown, Copy, Check} from 'react-bootstrap-icons';
import $api from '../../http/api.config';

const Attachments = observer(({
    title,
    attachment_type,
    project_id
}) => {
    const [files, setFiles] = useState([]);
    const [copiedIndices, setCopiedIndices] = useState({});
    const [newLink, setNewLink] = useState(''); // Новая ссылка
    const [error, setError] = useState(''); // Ошибка валидации
    const [isEditing, setIsEditing] = useState(false); // Режим редактирования
    const [isLoading, setIsLoading] = useState(false); // Состояние загрузки

    useEffect(() => {
        $api.get(`/api/v1/projects/${project_id}/files/`, {
            params: {
                "attachment_type": attachment_type
            }
        }).then(response => {
            setFiles(response.data)
        });
    }, []);

    const handleCopyToClipboard = (text, index) => {
        navigator.clipboard.writeText(text)
          .then(() => {
            setCopiedIndices(prev => ({ ...prev, [index]: true }));
          })
          .catch(err => {
            console.error('Ошибка при копировании текста: ', err);
          });

        setTimeout(() => {
          setCopiedIndices(prev => ({ ...prev, [index]: false }));
        }, 1500);
    };

    const handleReplaceLink = () => {
        setIsEditing(true); // Включаем режим редактирования
    };

    const handleSaveLink = (e) => {
        e.preventDefault();

        // Проверка на валидность ссылки
        if (!isValidUrl(newLink)) {
            setError('Пожалуйста, введите корректную ссылку.');
            return;
        }

        // Подготавливаем тело запроса
        const body = {
            attachment_type: attachment_type,
            path: newLink
        };

        // Включаем состояние загрузки
        setIsLoading(true);

        // Отправляем запрос на сервер
        $api.post(`/api/v1/projects/${project_id}/add_attachment/`, body)
            .then(response => {
                // Добавляем новую ссылку в список
                setFiles(prevFiles => [...prevFiles, response.data]);

                // Очищаем поле ввода и выключаем режим редактирования
                setNewLink('');
                setIsEditing(false);
            })
            .catch(err => {
                // Обрабатываем ошибку
                if (err.response) {
                    // Ошибка от сервера (например, 400 или 500)
                    setError((err.response.data.error || err.response.data.detail) || 'Произошла ошибка при сохранении ссылки.');
                } else if (err.request) {
                    // Ошибка сети (запрос не дошёл до сервера)
                    setError('Ошибка сети. Проверьте подключение к интернету.');
                } else {
                    // Другие ошибки
                    setError('Произошла непредвиденная ошибка.');
                }
            })
        .finally(() => {
            // Выключаем состояние загрузки
            setIsLoading(false);
        });
    };

     const handleCancelSaveLink = () => {
         setNewLink(''); // Очищаем поле ввода
         setIsEditing(false); // Выключаем режим редактирования
         setError(''); // Очищаем ошибку
     }

    const isValidUrl = (url) => {
        // Простая проверка на валидность ссылки
        try {
            new URL(url);
            return true;
        } catch (e) {
            return false;
        }
    };


    return (
        <>
            <div className="accordion">
                <section>
                    <input
                        type="checkbox"
                        className="accordion__checkbox"
                        id={"accordion-heading-" + attachment_type}
                    />
                    <label
                        className="accordion__heading"
                        htmlFor={"accordion-heading-" + attachment_type}
                    >
                        <p className="small">
                            {title ? title : "Файлы"}
                            <ArrowDown />
                        </p>
                    </label>
                    <div className="accordion__content">
                        <div className="files">
                            <ul>
                                { (files && files.length > 0) ?
                                    <>{files.map(function(el, index) {
                                        return <li key={index}>
                                            <a href={el.path} target="_blank" className={'small ' + (index + 1 === files.length ? '' : ' line-through')}>
                                                {el.path}
                                            </a>
                                            &nbsp;&nbsp;
                                            {copiedIndices[index] ?
                                                <Check />
                                                :
                                                <Copy onClick={() => handleCopyToClipboard(el.path, index)} />
                                            }
                                        </li>
                                    })}</>
                                    :
                                    <li className="small">Ссылок нет</li>
                                }
                            </ul>
                            {isEditing ? (
                                <div>
                                    <input
                                        type="text"
                                        value={newLink}
                                        onChange={(e) => setNewLink(e.target.value)}
                                        placeholder="Введите новую ссылку"
                                        className="form-control"
                                        disabled={isLoading} // Блокируем поле ввода во время загрузки
                                    />
                                    {error && <p className="text-danger small mb-0">{error}</p>}
                                    <button
                                        onClick={handleSaveLink}
                                        className="btn btn-success mt-2"
                                        disabled={isLoading} // Блокируем кнопку во время загрузки
                                    >
                                        {isLoading ? (
                                            <>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                Сохранение...
                                            </>
                                        ) : (
                                            "Сохранить"
                                        )}
                                    </button>
                                    <div onClick={handleCancelSaveLink} className="btn btn-secondary mt-2 ms-2">
                                        Отменить
                                    </div>
                                </div>
                            ) : (
                                <div className="btn btn-dark" onClick={handleReplaceLink}>
                                    {(files && files.length > 0) ? "Заменить ссылку" : "Добавить ссылку"}
                                </div>
                            )}
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
});

export default Attachments;