import { BasePermissions } from "../permissions";
import PermissionModelTypes from "../types";

class OverworkPermissions extends BasePermissions {
	constructor(user) {
		super(user, PermissionModelTypes.OVERWORK);
	}

	canView(): boolean {
		return super.canView() && !this.isGuest();
	}

	canChange(): boolean {
		return super.canChange() && !this.isGuest();
	}

	canAdd(): boolean {
		return super.canAdd() && !this.isGuest();
	}

	canDelete(): boolean {
		return super.canDelete() && !this.isGuest();
	}
}

export {
	OverworkPermissions
};