import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import Loader from "../../loader/Loader";

const CustomerDetail = ({BASE_URL}) => {
    const [showDeleteModal, setDeleteModal] = useState(false);
    const [showChangeModal, setShowChangeModal] = useState(false);
    const [customer, setCustomer] = useState(null);
    const [isLoaded, setIsLoaded] =  useState(false);
    
    const [number, setNumber] = useState('');
    const [email, setEmail] = useState('');
    
    const [resultMessage, setResultMessage] = useState();
    const [user, setUser] = useState();
    const params = useParams();
    const navigate = useNavigate();

    const id = params.id;

    useEffect(() => {
        const url = BASE_URL + '/api/customers/' + id + '/';
        axios.get(url, {
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        }).then(response => {
            setCustomer(response.data.customer);
            setUser(response.data.user);

            const number = response.data.number;
            const email = response.data.email;            
            if(number)
                setNumber(number);
            if(email)
                setEmail(email);

            setIsLoaded(true);
        });
    }, []);

    const deleteCustomer = (e) => {
        const url = BASE_URL + '/api/customers/' + id + '/delete/';
        axios.get(url,  {
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        }).then(response => {
            navigate('/crm/customers');
        }).catch(err => {
            setResultMessage(dataChangedAlerts(err.response));
        });
    }

    const dataChangedAlerts = (response) => {
        let text = "Данные успешно изменены";
        let className = 'alert-success';
        if(response.status !==200) {
            text = response.data.detail ? response.data.detail : 'Не удалось изменить данные. Попробуйте позже.';
            className = 'alert-danger';
        }

        setTimeout(() => {setResultMessage(null)}, 20000);

        return <>
            <div className={'alert ' + className} role="alert">
                {text}
            </div>
        </>
    }

    const changeCustomerData = (e) => {
        e.preventDefault();

        let form = document.querySelector('form');
        let formData = new FormData(form);

        const url = BASE_URL + '/api/customers/' + id + '/edit/';
        
        axios.post(url, formData, {
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('token'),
                'Content-Type': 'multipart/form-data',
            }
        }).then(response => {
            const number = response.data.number;
            const email = response.data.email;
            
            if(number)
                setNumber(number);

            if(email)
                setEmail(email);

            if(response.data.customer) {
                setCustomer(response.data.customer);
            }
            
            setResultMessage(dataChangedAlerts(response));

            setIsLoaded(true);
        }).catch(err => {
            setResultMessage(dataChangedAlerts(err.response));
        });
    }

    const handleCloseDeleteModal = () => setDeleteModal(false);
    const handleCloseChangeModal = () => setShowChangeModal(false);
    const handleShowDeleteModal = () => setDeleteModal(true);
    const handleShowChangeModal = () => setShowChangeModal(true);

    return (
        <>
            { isLoaded ? 
                <div className="detail-page">
                    <div className="cnt_wrapper container-fluid text-left">
                        <div className="content bg-white rounded p-3">
                            <div className="control-block">
                                <h3 className="title">
                                    # {customer.id} 
                                </h3>
                                <div className="controls">
                                    { (user.manager || user.chief_constructor || user.admin) &&
                                        <div onClick={handleShowChangeModal} className="btn btn-outline-success">
                                            изменить
                                        </div> }
                                    { user.admin &&
                                        <div onClick={handleShowDeleteModal} className="btn btn-outline-danger">
                                            удалить
                                        </div> }
                                </div>
                            </div>
                            <div className="row">
                                <p className='label'>Наименование</p>
                                <div>
                                    <p className="value">
                                        {customer.name}
                                    </p>
                                </div>
                            </div>

                            <div className="row">
                                <p className='label'>Контакты</p>
                                { number &&  
                                    <p className='value'>
                                        Номер телефона:&nbsp;
                                        <a href={'tel:' + number}>
                                            {number}
                                        </a>
                                    </p> }
                                { email &&  
                                    <p className='value'>
                                        Email:&nbsp;
                                        <a href={'mailto:' + email}>
                                            {email}
                                        </a>
                                    </p> }
                                {/* { numbers.length > 0 && <>
                                    {Object.keys(numbers).map(function(item, key) {
                                        return <p className='value' key={key}>
                                            Телефон:&nbsp;
                                            <a href={'tel:' + numbers[item].contact}>
                                                {numbers[item].contact}
                                            </a>
                                        </p>
                                    })}
                                </>}
                                { numbers.length > 0 && <>
                                    {Object.keys(numbers).map(function(item, key) {
                                        return <p className='value' key={key}>
                                            Email:&nbsp;
                                            <a href={'tel:' + numbers[item].contact}>
                                                {numbers[item].contact}
                                            </a>
                                        </p>
                                    })}
                                </>} */}
                                { ( !number && !email ) && <p>Нет сохранненных контактов</p> }
                            </div>
                        </div>
                    </div>
                </div>
                : <Loader />
            }

            { isLoaded &&
                <Modal show={showChangeModal} 
                        onHide={handleCloseChangeModal} 
                        scrollable={true}>
                    <Modal.Header closeButton>
                        <Modal.Title>Изменить данные заказчика</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form method='POST'>
                            {(user.manager || user.admin || user.constructor) &&
                                <div className="form-group text-left">
                                    <label htmlFor="name">Наименование</label>
                                    <input type="text" className="form-control" id="name" name='name' placeholder="Наименование" defaultValue={customer.name} />
                                </div> }
                            
                            <div className="group_of_numbers">
                                <div className="form-group text-left">
                                    <label htmlFor="phoneNumber">Номер телефона</label>
                                    <input type="text" className="form-control" id="phoneNumber" name='phoneNumber' placeholder="Номер телефона" defaultValue={number} />
                                </div>
                            </div>
                            {/* <div className="form-group text-right">
                                <Button className='btn-outline-primary'>Добавить номер</Button>
                            </div> */}
                            <div className="group_of_emails">
                                <div className="form-group text-left">
                                    <label htmlFor="email">Email</label>
                                    <input type="text" className="form-control" id="phoneNumber" name='email' placeholder="Электронная почта" defaultValue={email} />
                                </div>
                            </div>
                            {/* <div className="form-group text-right">                                
                                <Button className='btn-outline-primary'>Добавить email</Button>
                            </div> */}
                        </form>
                        {resultMessage}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseChangeModal}>
                            Отмена
                        </Button>
                        <Button variant="primary" onClick={changeCustomerData}>
                            Сохранить изменения
                        </Button>
                    </Modal.Footer>
                </Modal> 
            }
            
            <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Вы уверены?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Вы действительно хотите удалить данную задачу?<br /> Отменить это действие будет невозможно.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteModal}>
                        Отмена
                    </Button>
                    <Button variant="primary" onClick={deleteCustomer}>
                        Удалить
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default CustomerDetail;