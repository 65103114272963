import {useEffect, useRef, useState} from "react";
import {Button} from 'react-bootstrap';
import $api from "../../http/api.config";
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.css';
import Offcanvas from 'react-bootstrap/Offcanvas';
import {observer} from 'mobx-react-lite';
import {paymentOptions, statusOptions} from "../../util/choices/ProjectOptions";
import {useStore} from "../../store/hooks";
import {toJS} from "mobx";
import {EXECUTOR} from "../../util/constants";

const FilterModal = observer(({
    show,
    onHide,
    setElements,
    setNextPageUrl
}) => {
    const baseStore = useStore("baseStore");
    const authStore = useStore("authStore");
    const [filterData, setFilterData] = useState(toJS(baseStore.projectsFilterSet));
    const users = toJS(baseStore.getUsers.filter(u => u.groups.includes(EXECUTOR)));
    const { permissions } = authStore;

    // Токен отмены запроса
    const cancelToken = useRef(null);

    // Заказчики
    const [customersExcludeMode, setCustomersExcludeMode] = useState(true);
    const [selectedCustomers, setSelectedCustomers] = useState(() => {
        const stored = localStorage.getItem("filter_selected_customers");
        return stored ? stored.split(",").map(Number) : [];
    });

    // Статусы
    const [statusesExcludeMode, setStatusesExcludeMode] = useState(true);
    const [selectedStatuses, setSelectedStatuses] = useState(() => {
        const stored = localStorage.getItem("filter_selected_statuses");
        return stored ? stored.split(",").map(String) : [];
    });

    // Исполнители
    const [usersExcludeMode, setUsersExcludeMode] = useState(true);
    const [selectedUsers, setSelectedUsers] = useState(() => {
        const stored = localStorage.getItem("filter_selected_users");
        return stored ? stored.split(",").map(Number) : [];
    });

    // Оплата
    const [paymentsExcludeMode, setPaymentsExcludeMode] = useState(true);
    const [selectedPayments, setSelectedPayments] = useState(() => {
        const stored = localStorage.getItem("filter_selected_payments");
        return stored ? stored.split(",").map(String) : [];
    });


    useEffect(() => {
        baseStore.setProjectsFilterSet(filterData);
        console.log(filterData);
        filter(filterData);
        localStorage.setItem('filter_customers_exclude_mode', customersExcludeMode);
        localStorage.setItem('filter_statuses_exclude_mode', statusesExcludeMode);
        localStorage.setItem('filter_users_exclude_mode', usersExcludeMode);
        localStorage.setItem('filter_payments_exclude_mode', paymentsExcludeMode);
    }, [filterData]);

    const filter = async (filterData) => {
        // Отменяем предыдущий запрос
        if (cancelToken.current) {
            cancelToken.current.cancel('Operation canceled due to new request.');
        }

        // Создаем новый токен отмены
        const source = axios.CancelToken.source();
        cancelToken.current = source;

        try {
            const response = await $api.get('/api/v1/projects/', {
                params: filterData,
                cancelToken: source.token
            });

            // Проверяем, не был ли запрос отменен
            if (!axios.isCancel(response)) {
                setElements([...response.data.results]);
                setNextPageUrl(response.data.next);
            }
        } catch (error) {
            if (!axios.isCancel(error)) {
                // Обработка реальных ошибок
                console.error('Request failed:', error);
            }
        }
    };

    // Очистка при размонтировании
    useEffect(() => {
        return () => {
            if (cancelToken.current) {
                cancelToken.current.cancel('Component unmounted');
            }
        };
    }, []);

    const handleChange = (e) => {
        let { name, type, checked, value } = e.target;
        let newValue = type === 'checkbox' ? checked : value;

        if(type === 'checkbox') {
            newValue = checked;
        } else if(type === 'select-multiple') {
            newValue = Array.from(e.target.selectedOptions).filter(el => el.value.trim() !== '').map(el => el.value).join(',');
        }

        if(name === 'only_mine') {
            name = 'user';
            newValue = checked ? value : null;
        }
        if(name === 'all') {
            name = 'page_size';
            newValue = checked ? value : null;
        }

        if(newValue) {
            localStorage.setItem('filter_' + name, newValue);
            setFilterData({
                ...filterData,
                [name]: newValue
            });
        } else {
            localStorage.removeItem('filter_' + name);
            delete filterData[name];
            setFilterData({...filterData});
        }
    };

    const dropFilterData = () => {
        Object.keys(localStorage).map(key => {
            key.startsWith('filter_') && localStorage.removeItem(key);
        });
        setFilterData({});
        setSelectedCustomers([]);
        setSelectedStatuses([]);
        setSelectedUsers([]);
        setSelectedPayments([]);
    };

    const toggleCustomer = (id) => {
        setSelectedCustomers((prev) => {
            let result = prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id];
            localStorage.setItem("filter_selected_customers", result);
            setFilterData({
                ...filterData,
                ['customers']: result.join(',')
            });
            return result;
        });
    };

    const toggleAllCustomers = () => {
        if (selectedCustomers.length === baseStore.getCustomers.length) {
            setSelectedCustomers(prev => {
                localStorage.removeItem('filter_selected_customers');
                setFilterData({
                    ...filterData,
                    ['customers']: []
                });
                return [];
            });
        } else {
            setSelectedCustomers(prev => {
                const result = baseStore.getCustomers.map((c) => c.id);
                localStorage.setItem('filter_selected_customers', result);
                setFilterData({
                    ...filterData,
                    ['customers']: result.join(',')
                });
                return result;
            });
        }
    };

    const handleCustomersExcludeProp = (value) => {
        setCustomersExcludeMode(prev => {
            localStorage.setItem('filter_customers_exclude_mode', value);
            setFilterData({
                ...filterData,
                ['customers_exclude_mode']: value
            });
            return value;
        });
    };

    const toggleStatus = (id) => {
        setSelectedStatuses((prev) => {
            let result = prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id];
            localStorage.setItem("filter_selected_statuses", result);
            setFilterData({
                ...filterData,
                ['status']: result.join(',')
            });
            return result;
        });
    };

    const toggleAllStatuses = () => {
        if (selectedStatuses.length === statusOptions.length) {
            setSelectedStatuses(prev => {
                localStorage.removeItem('filter_selected_statuses');
                setFilterData({
                    ...filterData,
                    ['status']: []
                });
                return [];
            });
        } else {
            setSelectedStatuses(prev => {
                const result = statusOptions.map((s) => s.value);
                localStorage.setItem('filter_selected_statuses', result);
                setFilterData({
                    ...filterData,
                    ['status']: result.join(',')
                });
                return result;
            });
        }
    };

    const handleStatusesExcludeProp = (value) => {
        setStatusesExcludeMode(prev => {
            localStorage.setItem('filter_statuses_exclude_mode', value);
            setFilterData({
                ...filterData,
                ['statuses_exclude_mode']: value
            });
            return value;
        });
    };

    const toggleUser = (id) => {
        setSelectedUsers((prev) => {
            let result = prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id];
            localStorage.setItem("filter_selected_users", result);
            setFilterData({
                ...filterData,
                ['user']: result.join(',')
            });
            return result;
        });
    };

    const toggleAllUsers = () => {
        if (selectedUsers.length === users.length) {
            setSelectedUsers(prev => {
                localStorage.removeItem('filter_selected_users');
                setFilterData({
                    ...filterData,
                    ['user']: []
                });
                return [];
            });
        } else {
            setSelectedUsers(prev => {
                const result = users.map((u) => u.id);
                localStorage.setItem('filter_selected_users', result);
                setFilterData({
                    ...filterData,
                    ['user']: result.join(',')
                });
                return result;
            });
        }
    };

    const handleUsersExcludeProp = (value) => {
        setUsersExcludeMode(prev => {
            localStorage.setItem('filter_users_exclude_mode', value);
            setFilterData({
                ...filterData,
                ['users_exclude_mode']: value
            });
            return value;
        });
    };

    const togglePayment = (id) => {
        setSelectedPayments((prev) => {
            let result = prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id];
            localStorage.setItem("filter_selected_payments", result);
            setFilterData({
                ...filterData,
                ['payment']: result.join(',')
            });
            return result;
        });
    };

    const toggleAllPayments = () => {
        if (selectedPayments.length === paymentOptions.length) {
            setSelectedPayments(prev => {
                localStorage.removeItem('filter_selected_payments');
                setFilterData({
                    ...filterData,
                    ['payment']: []
                });
                return [];
            });
        } else {
            setSelectedPayments(prev => {
                const result = paymentOptions.map((p) => p.value);
                localStorage.setItem('filter_selected_payments', result);
                setFilterData({
                    ...filterData,
                    ['payment']: result.join(',')
                });
                return result;
            });
        }
    };

    const handlePaymentsExcludeProp = (value) => {
        console.log(value);
        setPaymentsExcludeMode(prev => {
            localStorage.setItem('filter_payments_exclude_mode', value);
            setFilterData({
                ...filterData,
                ['payments_exclude_mode']: value
            });
            return value;
        });
    };

    return (
        <Offcanvas show={show} onHide={onHide} placement="start">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Фильтр</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body style={{
                overflowY: "auto",
                maxHeight: "calc(100vh - 56px)",
                paddingBottom: "100px",
            }}>
                <div className="row">
                    <div className="col mb-3">
                        <Button onClick={dropFilterData} variant="outline-secondary">
                            Сбросить
                        </Button>
                    </div>
                </div>
                <div className="row">
                    <div className="col mb-2">
                        <div className="form-switch">
                            <input onChange={handleChange}
                                   className="form-check-input me-2"
                                   type="checkbox"
                                   name="all"
                                   defaultChecked={localStorage.getItem('filter_page_size') || false}
                                   value="5000"/>
                            <label className="form-label" htmlFor="all_projects">Все проекты</label>
                        </div>
                    </div>
                </div>
                {!authStore.guest_mode &&
                    <div className="row">
                        <div className="col mb-2">
                            <div className="form-switch">
                                <input onChange={handleChange}
                                       className="form-check-input me-2"
                                       type="checkbox"
                                       name="only_mine"
                                       defaultChecked={filterData.user === authStore.getUser?.id}
                                       value={authStore.getUser?.id}
                                />
                                <label className="form-label" htmlFor="user">Только мои</label>
                            </div>
                        </div>
                    </div>
                }
                <div className="row">
                    <div className="col mb-3">
                        <div className="mb-2 fw-bolder">Статус</div>
                        <div className="controls d-flex">
                            <div>
                                <button type="button" className="btn btn-sm btn-secondary mb-2" onClick={toggleAllStatuses}>
                                    {selectedStatuses.length === statusOptions.length ? "Сбросить" : "Выбрать все"}
                                </button>
                            </div>
                            <div className='d-flex flex-column align-items-center ms-2'>
                                <button
                                    type="button"
                                    className={`btn btn-sm btn-${statusesExcludeMode ? "danger" : "success"}`}
                                    onClick={() => handleStatusesExcludeProp(!statusesExcludeMode)}
                                >
                                    {statusesExcludeMode ? "Исключить" : "Выбрать только"}
                                </button>
                                <small style={{fontSize: '10px'}}>Выбрать / исключить</small>
                            </div>
                        </div>
                        <p className='mb-0'>
                            {statusesExcludeMode ? "Исключить:" : "Выбрать только:"}
                        </p>
                        <div style={{maxHeight: "200px", overflowY: "auto", border: "1px solid #ddd", padding: "10px"}}>
                            {statusOptions.map((status) => (
                                <div key={status.value} className="form-check">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id={`status-${status.value}`}
                                        checked={selectedStatuses.includes(status.value)}
                                        onChange={() => toggleStatus(status.value)}
                                    />
                                    <label className="form-check-label" htmlFor={`status-${status.value}`}>
                                        {status.name}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col mb-3">
                        <div className="mb-2 fw-bolder">Исполнитель</div>
                        <div className="controls d-flex">
                            <div>
                                <button type="button" className="btn btn-sm btn-secondary mb-2" onClick={toggleAllUsers}>
                                    {selectedUsers.length === users.length ? "Сбросить" : "Выбрать все"}
                                </button>
                            </div>
                            <div className='d-flex flex-column align-items-center ms-2'>
                                <button
                                    type="button"
                                    className={`btn btn-sm btn-${usersExcludeMode ? "danger" : "success"}`}
                                    onClick={() => handleUsersExcludeProp(!usersExcludeMode)}
                                >
                                    {usersExcludeMode ? "Исключить" : "Выбрать только"}
                                </button>
                                <small style={{fontSize: '10px'}}>Выбрать / исключить</small>
                            </div>
                        </div>
                        <p className='mb-0'>
                            {usersExcludeMode ? "Исключить:" : "Выбрать только:"}
                        </p>
                        <div style={{maxHeight: "200px", overflowY: "auto", border: "1px solid #ddd", padding: "10px"}}>
                            {users.map((user) => (
                                <div key={user.id} className="form-check">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id={`user-${user.id}`}
                                        checked={selectedUsers.includes(user.id)}
                                        onChange={() => toggleUser(user.id)}
                                    />
                                    <label className="form-check-label" htmlFor={`user-${user.id}`}>
                                        {user.last_name} {user.first_name}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col mb-3">
                        <div className="mb-2 fw-bolder">Оплата</div>
                        <div className="controls d-flex">
                            <div>
                                <button type="button" className="btn btn-sm btn-secondary mb-2" onClick={toggleAllPayments}>
                                    {selectedPayments.length === paymentOptions.length ? "Сбросить" : "Выбрать все"}
                                </button>
                            </div>
                            <div className='d-flex flex-column align-items-center ms-2'>
                                <button
                                    type="button"
                                    className={`btn btn-sm btn-${paymentsExcludeMode ? "danger" : "success"}`}
                                    onClick={() => handlePaymentsExcludeProp(!paymentsExcludeMode)}
                                >
                                    {paymentsExcludeMode ? "Исключить" : "Выбрать только"}
                                </button>
                                <small style={{fontSize: '10px'}}>Выбрать / исключить</small>
                            </div>
                        </div>
                        <p className='mb-0'>
                            {paymentsExcludeMode ? "Исключить:" : "Выбрать только:"}
                        </p>
                        <div style={{maxHeight: "200px", overflowY: "auto", border: "1px solid #ddd", padding: "10px"}}>
                            {paymentOptions.map((payment) => (
                                <div key={payment.value} className="form-check">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id={`payment-${payment.value}`}
                                        checked={selectedPayments.includes(payment.value)}
                                        onChange={() => togglePayment(payment.value)}
                                    />
                                    <label className="form-check-label" htmlFor={`payment-${payment.value}`}>
                                        {payment.name}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {(permissions.customer.canView) &&
                    <div className="row">
                        <div className="col mb-3">
                            <div className="mb-2 fw-bolder">Заказчики</div>
                            <div className="controls d-flex">
                                <div>
                                    <button type="button" className="btn btn-sm btn-secondary mb-2" onClick={toggleAllCustomers}>
                                        {selectedCustomers.length === baseStore.getCustomers.length ? "Сбросить" : "Выбрать все"}
                                    </button>
                                </div>
                                <div className='d-flex flex-column align-items-center ms-2'>
                                    <button
                                        type="button"
                                        className={`btn btn-sm btn-${customersExcludeMode ? "danger" : "success"}`}
                                        onClick={() => handleCustomersExcludeProp(!customersExcludeMode)}
                                    >
                                        {customersExcludeMode ? "Исключить" : "Выбрать только"}
                                    </button>
                                    <small style={{fontSize: '10px'}}>Выбрать / исключить</small>
                                </div>
                            </div>
                            <p className='mb-0'>
                                {customersExcludeMode ? "Исключить:" : "Выбрать только:"}
                            </p>
                            <div style={{maxHeight: "200px", overflowY: "auto", border: "1px solid #ddd", padding: "10px"}}>
                                {baseStore.getCustomers.map((customer) => (
                                    <div key={customer.id} className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id={`customer-${customer.id}`}
                                            checked={selectedCustomers.includes(customer.id)}
                                            onChange={() => toggleCustomer(customer.id)}
                                        />
                                        <label className="form-check-label" htmlFor={`customer-${customer.id}`}>
                                            {customer.name}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                }
            </Offcanvas.Body>
        </Offcanvas>
    )
});

export default FilterModal;