import { makeAutoObservable } from "mobx";
import $api from "../http/api.config";
import { initializeFilterData } from '../util/Filter';
import { customersSort } from '../util/sort';

export default class BaseStore {
	totalProjectsCountOnPage: number = 0;
	customers: Array<string> = [];
	users: Array<string> = [];
	projectsFilterSet: {};
	projectSearchFieldValue: string = "";
	isFetchingUsers: boolean = false; // Флаг для предотвращения повторных запросов
    isFetchingCustomers: boolean = false; // Флаг для предотвращения повторных запросов

	constructor() {
		makeAutoObservable(this);

		this.projectsFilterSet = initializeFilterData();
	}

	initialize() {
		this.fetchCustomers();
        this.fetchUsers();
	}

	// Методы для выполнения запросов
    private fetchCustomers() {
        if (!this.isFetchingCustomers) {
            this.isFetchingCustomers = true;
            $api.get<Array<string>>("/api/v1/customers/")
                .then((response) => {
                    this.setCustomers(customersSort(response.data));
                })
                .finally(() => {
                    this.isFetchingCustomers = false;
                });
        }
    }

    private fetchUsers() {
        if (!this.isFetchingUsers) {
            this.isFetchingUsers = true;
            const params = {
                is_active: 'True',
                page_size: 1000
            }
            $api.get<{results: Array<string>}>("/api/v1/users/", { params }).then((response) => {
                    this.setUsers(response.data.results);
                })
                .finally(() => {
                    this.isFetchingUsers = false;
                });
        }
    }

    get getCustomers() {
        if (this.customers.length === 0 && !this.isFetchingCustomers) {
            this.fetchCustomers();
        }
        return this.customers;
    }

    get getUsers() {
        if (this.users.length === 0 && !this.isFetchingUsers) {
            this.fetchUsers();
        }
        return this.users;
    }

	setCustomers(customers: Array<string>) {
		this.customers = customers;
	}

	setUsers(users: Array<string>) {
		this.users = users;
	}

	setTotalProjectsCountOnPage(count: number) {
		this.totalProjectsCountOnPage = count;
	}

	setProjectsFilterSet(state: {}) {
		this.projectsFilterSet = state;
	}

	setProjectSearchFieldValue(value: string) {
		this.projectSearchFieldValue = value;
	}
}