
const customersSort = (list) => {
	const priorityIds = [104, 22, 99];
	const priorityMap = new Map(priorityIds.map((id, index) => [id, index]));
	return list.sort((a, b) => {
		const aPriority = priorityMap.has(a.id) ? priorityMap.get(a.id) : Infinity;
		const bPriority = priorityMap.has(b.id) ? priorityMap.get(b.id) : Infinity;
		return aPriority - bPriority || a.name.localeCompare(b.name);
	})
}

export {
	customersSort,
}
