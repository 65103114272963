const defaultPermissions = {
    canView: false,
    canChange: false,
    canAdd: false,
    canDelete: false,
};

class BasePermissions {
    constructor(user, page) {
        this.user = user;
        this.page = page;
        this.permissions = user.permissions;
    }

    getPermissions() {
        return {
            canView: this.isSuperuser() || this.canView(),
            canChange: this.isSuperuser() || this.canChange(),
            canAdd: this.isSuperuser() || this.canAdd(),
            canDelete: this.isSuperuser() || this.canDelete(),
        }
    }

    isSuperuser() {
        return this.user.is_superuser;
    }

    isGuest() {
        return this.user.guest_mode;
    }

    canView() {
        return this.permissions.filter(p => p === 'view_' + this.page).length > 0;
    }

    canChange() {
        return this.permissions.filter(p => p === 'change_' + this.page).length > 0;
    }

    canAdd() {
        return this.permissions.filter(p => p === 'add_' + this.page).length > 0;
    }

    canDelete() {
        return this.permissions.filter(p => p === 'delete_' + this.page).length > 0;
    }
}




export {
    defaultPermissions,
    BasePermissions
}